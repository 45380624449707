
import React, { useEffect, useState } from "react";
import { getRestaurantMenuUICustomizations } from "../../../utilities/restaurantMenuCustomizationsUtil";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllRestaurantMenuData, setRestaurantMenuUICustomizations } from "../../../../../redux/resturantWidgetSlice";
import PopupWrapper from "../../ResturatnWidget/PopupWrapper";
import SkeletonLoader from "./components/SkeletonLoader";
import MenuTabs from "./components/Tabs/MenuTabs";
import Layout from "./components/layouts/Layout";



const RestaurantWidget = (props) => {
    const dispatch = useDispatch();
    const [widgetState, setWidgetState] = useState({
        isPopup: null,
        layout: null,
        customizations: null,
        themeAvailable: false,
        isInitialLoading: true,
    });
    const { menuData: { isLoading }, defualtSelectedLocation, organizationID } = useSelector((state) => state.resturantData);

    useEffect(() => {
        if (props.menu) {
            localStorage.removeItem("QUANTITY")
            localStorage.removeItem("ITEM")
            localStorage.removeItem("UPDATE-QUANTITY")
            let menuData = JSON.parse(props.menu)
            let menuCustomizationValues = getRestaurantMenuUICustomizations(menuData)
            dispatch(setRestaurantMenuUICustomizations({ uiCustomizations: menuCustomizationValues }));
            setWidgetState({
                customizations: menuData,
                isPopup: menuData?.selectedCard === 'popup' ? true : false,
                layout: menuData?.selectedLayout,
                themeAvailable: menuData?.selctedTheme !== "removeTheme" ? true : false,
            });


        }
    }, [])
    const getMenuData = () => {
        dispatch(fetchAllRestaurantMenuData({
            CloudLocationID: defualtSelectedLocation.CloudLocationID,
            OrganizationID: organizationID,
        }));
    }

    useEffect(() => {
        setWidgetState(prevState => ({
            ...prevState,
            isInitialLoading: false,
        }));
        getMenuData();
    }, [defualtSelectedLocation]);

    useEffect(() => {
        if (widgetState.isInitialLoading) {
            setWidgetState(prevState => ({
                ...prevState,
                isInitialLoading: false,
            }));
        }
    }, [isLoading]);
    return (
        <div>
            <PopupWrapper isPopup={widgetState.isPopup}>
                {widgetState.isInitialLoading && isLoading ?
                    <SkeletonLoader />
                    :
                    <MenuTabs isPopupWidget={widgetState.isPopup}>
                        {isLoading ? <SkeletonLoader /> : <Layout layout={widgetState.layout} />}
                    </MenuTabs>}
            </PopupWrapper>
        </div>
    )
}
export default RestaurantWidget