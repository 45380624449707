import React from "react";
import { useSelector } from "react-redux";
import { Col, Typography } from "witmeg-ui-system";
import "./Layout.scss"
import _ from 'lodash';
import { getCurrencyFormat, LOCAL_STORAGE_ITEMS_NAMES } from "../../utilities/helper/helper";
import deal from "../../assets/images/deal.svg"
const IMAGE_PLACEHOLDER = "https://st2.depositphotos.com/3904951/8925/v/450/depositphotos_89250312-stock-illustration-photo-picture-web-icon-in.jpg"

const Layout = ({ layout }) => {
    const { menuData: { selectedMainMenuData, uiCustomizations, storage: { data } }, currency, menuData
    } = useSelector((state) => state.resturantData);
    const currencyFormat = getCurrencyFormat(currency);
    const selectedOrderType = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) && JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)).defaultOrderType
    const getThePrice = (price) => {
        let priceValue;

        switch (selectedOrderType) {
            case "delivery":
                priceValue = price.DeliveryPrice;
                break;
            case "table":
                priceValue = price.TablePrice;
                break;
            default:
                priceValue = price.PickupPrice;
                break;
        }
        return currencyFormat(priceValue / 100);
    };
    return (
        <div className="Layout">
            {selectedMainMenuData && selectedMainMenuData.length > 0 && selectedMainMenuData.map((data, index) => {
                return (
                    <div key={index}>
                        <Typography type="title" level={4} pattern="bold" className="title-menu" >{data?.Title.en}</Typography>
                        <div className={layout}>
                            {data.MenuEntities.map(((menuEntity, key) => {
                                const menuItem = _.find(menuData.storage.data.Items, { MenuItemID: menuEntity.ID });
                                return (
                                    <Col className="column-styles" key={key}>
                                        <a href={'/item/' + menuEntity.ID + "/" + "new"}>
                                            <div className="column">
                                                <div className="item-details">
                                                    <div className="item-dela-cont">
                                                        {layout !== "layout2" && layout !== "layout4" && <img alt="" src={menuItem.ImageURL ? menuItem.ImageURL : IMAGE_PLACEHOLDER} className="item-image" />}

                                                        {menuItem?.MetaData?.IsDealProduct && <div className="deal"><div className="trp">Deal</div></div>}
                                                    </div>
                                                    <div className="item-des">
                                                        <Typography type="title" level={4}
                                                        //  style={uiCustomizations.productItem.name}
                                                        >{menuItem.Title.en.toCapitalFirst()}</Typography>
                                                        <Typography type="title" level={5}
                                                            // style={uiCustomizations.productItem.description} 
                                                            className="details">{menuItem.Description.en.toCapitalFirst()}</Typography>
                                                    </div>
                                                </div>
                                                <div className="price-details">
                                                    <Typography type="title" level={5} pattern="bold" className="price">{getThePrice(menuItem.PriceInfo.Price)}</Typography>
                                                </div>

                                            </div>
                                        </a>
                                    </Col>
                                )




                            }))}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export default Layout