import globalValues from "../../../../../utilities/apiRequests/globalValues";


const {
    REACT_APP__LOYALTY_REGISTRY_BASEURL,
    REACT_APP__NEUTRIPOS_BASEURL,
    REACT_APP_PAYMENT_BASE_URL
} = globalValues.baseURLS;
const endpointService = {
    loyaltyRegistryServer: {
        getAllRestaurantMenuItems: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/menu`,
        addItemToCart: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/order/add`,
        order: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/order`,
        placeOrder: `${REACT_APP__LOYALTY_REGISTRY_BASEURL}/v1/retailpacx/place-order`,
    },
    nuetriposServer: {
        getFloorData: `${REACT_APP__NEUTRIPOS_BASEURL}/v1/webneutripos/resttable`
    },
    paymentServer: {
        getStorePaymentMethods: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/paymentmethod/fetch`,
        passMethodDataToDropIn: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/payment/instant`,
        sendPaymentDetails: `${REACT_APP_PAYMENT_BASE_URL}/v1/p1/store/ecom/pay/paymentdetail`
    }

};

export default endpointService;

