import React from 'react';
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import "bootstrap/dist/css/bootstrap.min.css";
import "../themes/default/css/default.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "../themes/default/header.component";
import Navbar from "../themes/default/navbar.component";
import Footer from "../themes/default/footer.component";
import Pages from "./pages";
import HomePage from "./home";
import Articles from './articles'

import ClothingHeader from "../themes/cloths/header.component";
import ClothingFooter from "../themes/cloths/footer.component";
import ClothingHomePage from "../themes/cloths/home";

import MartfuryHeader from "../themes/martfury/header.component";
import MartfuryFooter from "../themes/martfury/footer.component";
import MartfuryHomePage from "../themes/martfury/home";

import ApparelHeader from "../themes/apparel/header.component";
import ApparelFooter from "../themes/apparel/footer.component";
import ApparelHomePage from "../themes/apparel/home";

import ApparelcasiHeader from "../themes/apparel-casi/header.component";
import ApparelcasiFooter from "../themes/apparel-casi/footer.component";
import ApparelcasiHomePage from "../themes/apparel-casi/home";


import WitmegHeader from "../themes/witmeg/header.component";
import WitmegFooter from "../themes/witmeg/footer.component";

import SpicewayNavbar from "../themes/spiceway/navbar.component";
import SpicewayHeader from "../themes/spiceway/header.component";
import SpicewayFooter from "../themes/spiceway/footer.component";
import SpicewayHomePage from "../themes/spiceway/home";

import MeatShopHeader from "../themes/meatshop/header.component";
import MeatShopFooter from "../themes/meatshop/footer.component";
import MeatShopHomePage from "../themes/meatshop/home";

import StationaryHeader from "../themes/stationary/header.component";
import StationaryFooter from "../themes/stationary/footer.component";
import StationaryHomePage from "../themes/stationary/home";


import Products from "../shop/products";
import SearchItems from "../shop/products-search";
import ProductsByID from "../shop/productsbyid";
import ProductsByBrandID from "../shop/productsbybrandid";
import ProductsDetailsByID from "../shop/productdetails";
import Login from "../shop/login";
import MyCart from "../shop/cart.js";
import Checkout from "../shop/checkout";
import Payment from "../shop/payment";
import AccountLogin from "../shop/account-login";
import AccountForgot from "../shop/account-forgot";
import AccountMember from "../shop/account-member";
import AccountPayment from "../shop/account-payment";
import AccountRegister from "../shop/account-register";
import AccountVerify from "../shop/account-register-verify";
import AccountYourDetails from "../shop/account-register-yourdetails";
import OrderSuccess from "../shop/order-success";
import AccountMyInfo from "../shop/account-my-info";
import AccountAddresses from "../shop/account-addresses";
import AccountOrder from "../shop/account-order";
import AccountMyWishList from "../shop/account-my-wishlists";

import { Container } from 'react-bootstrap';
import config from 'react-global-configuration';
import "../config.js";
import axios from 'axios';
import { Spin } from 'witmeg-ui-system';
import PageUndefined from './default/404Page';
import OrderConfirmation from './default/WidgetCompoentens/common/OrderConfirmation';
import OrderSummaryTable from './default/WidgetCompoentens/ResturatnWidget/Common/OrderSummaryTable';
// import PaymentDetailsForm from './default/WidgetCompoentens/ResturatnWidget/Common/PaymentDetailsForm';
import PaymentDetails from './default/WidgetCompoentens/common/PaymentDetails';
import OrderSuccessForm from './default/WidgetCompoentens/ResturatnWidget/Common/OrderSuccessForm';
import { connect } from 'react-redux';
import { syncRestaurantMenuCartDetails } from '../redux/resturantWidgetSlice';
import { setCompanyData, setSettingsData } from '../redux/companyDataSlice';
import SiteUnderMaintenance from '../components/SiteUnderMaintain';
import { getCompanyDetailsByDomain, getSettingsByOrgId } from './default/utilities/apiRequests/witmegRetailPaxServerRequests';
import DefaultPageWrapper from './default/DefaultPageWrapper';
import ShankarPageWrapper from './sharkar/DefaultPageWrapper';
import AdeynPayment from '../shop/adeynPayment.js';
import SingleItemPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/SingleItemPage/SingleItemPage.js';
import OrderPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/OrderPage/OrderPage.js';
import OrderSummaryPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/OrderPage/OrderSummaryPage.js';
import OrderSuccessPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/OrderPage/OrderSuccessPage.js';
import TimeoutPopup from './default/WidgetCompoentens/Shaakira/RestaurantWidget/components/popups/TimeoutPopup.js';
import PaymentSelectionPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/Payment/PaymentSelectionPage.js';
import OrderFailedPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/OrderPage/OrderFailedPage.js';
import OrderTrackPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/OrderPage/OrderTrackPage.js';
import OrderStatusPage from './default/WidgetCompoentens/Shaakira/RestaurantWidget/pages/OrderPage/OrderStatusPage.js';

const Loader = () => (
    <div className="divLoader">
        <svg className="svgLoader" viewBox="0 0 100 100" width="10em" height="10em">
            <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
        </svg>
    </div>
);

class Application extends React.Component {
    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.state = { newCompanyResult: [], companyresult: [], loading: true, pageid: '', pageName: '', urlParams: '', localStorageValue: localStorage.getItem('date') || null, timeHasPassed: false, };
    }
    componentDidMount() {
        this.localStorageCheckInterval = setInterval(this.checkStoredTime, 1000);
    }
    fetchData = async () => {
        // const host = "" //window.location.host;
        // // const origin = "https://uat-retailpacxtest.rewardticks.com" // window.location.origin;
        // const origin = "https://www.throbox.com" // window.location.origin;

        const host = window.location.host;
        const origin = window.location.origin;

        const _response = await getCompanyDetailsByDomain({
            ID: '',
            OrganisationID: '',
            SubDomain: host,
            CustomerDomain: origin,
            Start: 0,
            Limit: 10,
            IsSearchApplicable: false,
            Search: {
                Key: '',
                Value: '',
            },
        });
        console.log(_response[0], "response[0]")

        if (_response && _response.length > 0) {
            const _companyData = {
                organizationID: _response[0].OrganisationID,
                address: {
                    line1: _response[0].AddressLine1,
                    line2: _response[0].AddressLine2,
                    city: _response[0].City,
                    state: _response[0].StateProvinceRegion,
                    postcode: _response[0].PostZipCode,
                    country: _response[0].Country,
                },
                companyID: _response[0].ID,
                shopName: _response[0].Name,
                bigLogoURL: _response[0].Logo,
                smallLogoURL: _response[0].Logo,
                siteURL: _response[0].CustomerDomain,
                email: _response[0].Email,
                contactNumber: _response[0].Phone,
                websiteType: _response[0].WebSiteType,
                PaymentIntegration: _response[0].PaymentIntegration
            };
            this.props.initializeCompanyData(_companyData);

            this.setState({ companyresult: _response[0], loading: false });

            const _settingsResponse = await (getSettingsByOrgId(_response[0].OrganisationID))
            if (_settingsResponse && _settingsResponse.length > 0) {
                this.props.initializeSettingsData(_settingsResponse[0])
            }

            console.log("_settingsResponse", _settingsResponse)
            console.log("_companyData", _companyData)
        }


    }

    componentDidMount() {
        this.props.syncRestaurantMenuCartStateData();
        this.fetchData()    // RMEOVE spiceway.throbox.com WHEN DONE TO WINDOW.LOCATION.hostname
        // axios.get(config.get('backendURL') + 'company/bydomain/' + window.location.hostname)
        //   .then(result => this.setState({ companyresult: result, loading: false }))
        //   .catch(function (error) {
        //     console.log(error);
        //   })
        // Listen for global click events on the entire document
        document.addEventListener('click', this.handleClick);

        // Check the stored time on initial mount
        this.checkStoredTime();
    }
    componentWillUnmount() {
        // Remove the click event listener when the component unmounts
        document.removeEventListener('click', this.handleClick);
    }

    handleClick = () => {
        this.checkStoredTime();
    };
    checkDate = () => {
        if (localStorage.getItem("date")) {
            let IsPassed = false
            let currentDate = new Date();
            let storedDate = new Date(localStorage.getItem("date"));
            if ((currentDate - storedDate) > 1800000) {
                IsPassed = true
            } else {
                IsPassed = false
            }
            return IsPassed
        } else {
            return false
        }
    }
    checkStoredTime = () => {
        const storedTime = localStorage.getItem('date');
        if (storedTime) {
            const timeHasPassed = this.checkDate()
            this.setState({ timeHasPassed });
        }
    };

    //Navigation to Page Function
    handleNavbarPageClick = async (pageid) => {
        axios.post(process.env.REACT_APP_TOKENSERVICE_URL, {
            isLoginServrRequet: false,
            serviceName: "retailpacx",
        }).then(async response => {
            await axios.post(
                `${process.env.REACT_APP__WITMEG_RETAILPAXSERVER__BASEURL}/v1/retail/pagesinfo/id`,

                {
                    ID: pageid,
                    CompanyID: config.get('companyID')
                },
                {
                    headers: {
                        Authorization: `Bearer ${response.data.token}`,
                    },
                }).then(async result => {
                    this.setState({
                        pageid: result.data.Result[0].ID,
                        pageName: result.data.Result[0].Slug,
                        key: Math.random()
                    })

                    window.history.replaceState(null, null, `/pages/${result.data.Result[0].Slug}`)
                    // window.history.replaceState(null, null, '/pages/' + result.data.Result[0].Slug)
                    window.location.reload()
                }
                )
        })
    }


    render() {
        console.log(this.state.companyresult, "this.state.companyresult.", config.get('Theme'), "them")
        if (this.state.companyresult.length !== 0) {


            config.set({
                companyID: (this.state.companyresult)?.ID,
                SenderEmail: (this.state.companyresult)?.Email,
                Theme: (this.state.companyresult)?.Theme,
                favicon: (this.state.companyresult)?.Favicon,
                OrganisationID: (this.state.companyresult)?.OrganisationID,
                companyLogo: (this.state.companyresult)?.Logo,
                companyLogoCDN: (this.state.companyresult)?.LogoCDN,
                companyPageTitle: (this.state.companyresult)?.Name,
                LocationID: (this.state.companyresult)?.LocationID,
                WorldPayServiceKey: (this.state.companyresult)?.WorldPayServiceKey,
                WorldPayClientKey: (this.state.companyresult)?.WorldPayClientKey,
                WishListEnabled: (this.state.companyresult)?.WishListEnabled,
                HelpPage: (this.state.companyresult)?.HelpPage,
                SocialMedia: (this.state.companyresult)?.SocialMedia,
                DeliveryMessage: (this.state.companyresult)?.DeliveryMessage,
                ShopNowItems: (this.state.companyresult)?.ShopNowItems,
                FBappid: (this.state.companyresult)?.FBappid,
                FBpageid: (this.state.companyresult)?.FBpageid,
                CDNPath: (this.state.companyresult)?.CDNPath,
                backendURL: config.get('backendURL'),
                API_URL_cust: config.get('API_URL_cust'),
                API_productregistry: config.get('API_productregistry'),
                rest_API_productregistry: config.get('rest_API_productregistry'),
                rest_API_productregistry_V2: config.get('rest_API_productregistry_V2'),
                rest_API: config.get('rest_API'),
                ProductjwtAud: config.get('ProductjwtAud'),
                ProductjwtIss: config.get('ProductjwtIss'),
                ProductjwtExp: config.get('ProductjwtExp'),
                ProductprivateKey: config.get('ProductprivateKey'),
                API_salesregistry: config.get('API_salesregistry'),
                SalesjwtAud: config.get('SalesjwtAud'),
                SalesjwtIss: config.get('SalesjwtIss'),
                SalesjwtExp: config.get('SalesjwtExp'),
                SalesprivateKey: config.get('SalesprivateKey'),
                LoginprivateKey: config.get('LoginprivateKey'),
                CustjwtAud: config.get('CustjwtAud'),
                CustjwtIss: config.get('CustjwtIss'),
                CustjwtExp: config.get('CustjwtExp'),

                LoginAuthorizationKey: config.get('LoginAuthorizationKey')
            }, { freeze: false });
            document.getElementById("root").setAttribute("class", config.get('Theme'))

        }

        const isHome = window.location.pathname === '/'
        console.log('isHome==>> ', isHome)
        console.log('pageId==>> ', this.state.pageid)

        return (

            // max-height: calc(100vh - 80px);
            // overflow-y: auto;

            <React.Fragment>
                {this.state.timeHasPassed ? <TimeoutPopup onOkConfirm={() => {
                    localStorage.clear()
                    window.location.href = '/';
                }} /> : ""}
                {this.state.loading
                    ? <Spin tip="Loading"
                        className="eco-mt-44 spinner" size="large"> <div className="content" /></Spin>
                    :

                    this.state.companyresult?.IsPublished
                        ?
                        (
                            <Router>
                                {/* <div style={{
                                    // maxHeight: "calc(100vh - 0px)",
                                    // overflowX: "hidden",
                                    // height: '100vh',
                                    // width: '100vw',
                                }}  > */}
                                {(() => {
                                    switch (config.get('Theme')) {
                                        case 'Cloths':
                                            return (
                                                <>
                                                    <ClothingHeader />
                                                    <Route exact path="/" component={ClothingHomePage} />
                                                </>
                                            )
                                        case 'Martfury':
                                            return (
                                                <>
                                                    <MartfuryHeader parentPageCallback={this.handleNavbarPageClick} />
                                                    <Route exact path="/" component={MartfuryHomePage} />
                                                </>
                                            )
                                        case 'Apparel':
                                            return (
                                                <>
                                                    <ApparelHeader parentPageCallback={this.handleNavbarPageClick} />
                                                    <Route exact path="/" component={ApparelHomePage} />
                                                </>
                                            )
                                        case 'Apparel-casi':
                                            return (
                                                <>
                                                    <ApparelcasiHeader parentPageCallback={this.handleNavbarPageClick} />
                                                    <Route exact path="/" component={ApparelcasiHomePage} />
                                                </>
                                            )
                                        case 'Spiceway':
                                            return (
                                                <>
                                                    <SpicewayNavbar parentPageCallback={this.handleNavbarPageClick} />
                                                    <SpicewayHeader parentPageCallback={this.handleNavbarPageClick} />
                                                    <Route exact path="/" component={SpicewayHomePage} />
                                                </>
                                            )
                                        case 'MeatShop':
                                            return (
                                                <>
                                                    <MeatShopHeader parentPageCallback={this.handleNavbarPageClick} />
                                                    <Route exact path="/" component={MeatShopHomePage} />
                                                </>
                                            )
                                        case 'Stationary':
                                            return (
                                                <>
                                                    <StationaryHeader parentPageCallback={this.handleNavbarPageClick} />
                                                    <Route exact path="/" component={StationaryHomePage} />
                                                </>
                                            )
                                        case 'Witmeg':
                                            return (
                                                <>
                                                    <WitmegHeader />
                                                    <Route exact path="/" component={ApparelHomePage} />
                                                </>
                                            )

                                        case 'Shankar':
                                            return (
                                                <>
                                                    <ShankarPageWrapper navbarPageClickCallback={this.handleNavbarPageClick}>
                                                        <Route exact path="/" render={(props) => <HomePage {...props} theme="Shankar" />} />
                                                        <Route path="/pages/:id" render={(props) => <HomePage {...props} theme="Shankar" />} />

                                                        <Route exact path="/checkout" component={OrderConfirmation} theme="Shankar" />
                                                        <Route exact path="/order-summary" component={OrderSummaryTable} theme="Shankar" />
                                                        <Route exact path="/payment-details" component={PaymentDetails} theme="Shankar" />
                                                        <Route exact path="/order-success/:id" component={OrderSuccessForm} theme="Shankar" />

                                                        {/* Restaurant Menu QR Code paths */}
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/table/:tableid" component={HomePage} />
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/delivery" component={HomePage} />
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/takeaway" component={HomePage} />

                                                        {/* E Commerce Widgets */}
                                                        <Route exact path="/products" render={(props) => <Products {...props} theme="Shankar" />} />
                                                        <Route exact path="/search" render={(props) => <SearchItems {...props} theme="Shankar" />} />
                                                        <Route exact path="/search/:name" render={(props) => <SearchItems {...props} theme="Shankar" />} />
                                                        <Route exact path="/search/:name/:categoryId" render={(props) => <SearchItems {...props} theme="Shankar" />} />
                                                        <Route exact path="/group/:id" render={(props) => <ProductsByID {...props} theme="Shankar" />} />
                                                        <Route exact path="/group/:id/:name" render={(props) => <ProductsByID {...props} theme="Shankar" />} />
                                                        <Route exact path="/product/:id" render={(props) => <ProductsDetailsByID {...props} theme="Shankar" />} />
                                                        {/* Product Landing Pagedgets */}

                                                        {/*User Profile Actions */}
                                                        <Route exact path="/cart" component={MyCart} />
                                                        <Route exact path="/login" component={Login} />
                                                        <Route exact path="/payment" component={Payment} />
                                                        <Route exact path="/account/login" component={AccountLogin} />
                                                        <Route exact path="/account/forgot" component={AccountForgot} />
                                                        <Route exact path="/account/" component={AccountMember} />
                                                        <Route exact path="/account/member" component={AccountMember} />
                                                        <Route exact path="/account/register" component={AccountRegister} />
                                                        <Route exact path="/account/register/verify/:id" component={AccountVerify} />
                                                        <Route exact path="/account/register/yourdetails" component={AccountYourDetails} />
                                                        <Route exact path="/account/payment" component={AccountPayment} />
                                                        <Route exact path="/account/my-info" component={AccountMyInfo} />
                                                        <Route exact path="/account/addresses" component={AccountAddresses} />
                                                        <Route exact path="/account/my-wishlists" component={AccountMyWishList} />
                                                        <Route exact path="/account/order-history" component={AccountOrder} />
                                                    </ShankarPageWrapper>

                                                </>
                                            )
                                        default:
                                            return (
                                                <>
                                                    <DefaultPageWrapper navbarPageClickCallback={this.handleNavbarPageClick}>
                                                        <Route exact path="/adeynPayment" component={AdeynPayment} />
                                                        <Route exact path="/" component={HomePage} />
                                                        <Route path="/pages/:id" component={HomePage} />

                                                        {/* shaakira */}
                                                        <Route path="/item/:id/:itemId" component={SingleItemPage} />
                                                        <Route exact path="/order/checkout" component={OrderPage} />
                                                        <Route exact path="/order/summary" component={OrderSummaryPage} />
                                                        <Route exact path="/order/success/:id" component={OrderSuccessPage} />
                                                        <Route exact path="/order/payment/selection" component={PaymentSelectionPage} />
                                                        <Route exact path="/order/failed" component={OrderFailedPage} />
                                                        <Route exact path="/order/track" component={OrderTrackPage} />
                                                        <Route exact path="/order/status" component={OrderStatusPage} />




                                                        <Route exact path="/checkout" component={OrderConfirmation} />
                                                        <Route exact path="/order-summary" component={OrderSummaryTable} />
                                                        <Route exact path="/payment-details" component={PaymentDetails} />
                                                        <Route exact path="/order-success/:id" component={OrderSuccessForm} />


                                                        {/* Restaurant Menu QR Code paths */}
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/:orderType/:applicationId" component={HomePage} />
                                                        {/* <Route exact path="/:code/:organizationID/:cloudLocationID/delivery" component={HomePage} />
                                                        <Route exact path="/:code/:organizationID/:cloudLocationID/takeaway" component={HomePage} /> */}

                                                        {/* E Commerce Widgets */}
                                                        <Route exact path="/products" component={Products} />
                                                        <Route exact path="/search" component={SearchItems} />
                                                        <Route exact path="/search/:name" component={SearchItems} />
                                                        <Route exact path="/search/:name/:categoryId" component={SearchItems} />
                                                        <Route exact path="/group/:id" component={ProductsByID} />
                                                        <Route exact path="/group/:id/:name" component={ProductsByID} />
                                                        <Route exact path="/product/:id" component={ProductsDetailsByID} />
                                                        {/* Product Landing Pagedgets */}

                                                        {/*User Profile Actions */}
                                                        <Route exact path="/cart" component={MyCart} />
                                                        <Route exact path="/login" component={Login} />
                                                        <Route exact path="/payment" component={Payment} />
                                                        <Route exact path="/account/login" component={AccountLogin} />
                                                        <Route exact path="/account/forgot" component={AccountForgot} />
                                                        <Route exact path="/account/" component={AccountMember} />
                                                        <Route exact path="/account/member" component={AccountMember} />
                                                        <Route exact path="/account/register" component={AccountRegister} />
                                                        <Route exact path="/account/register/verify/:id" component={AccountVerify} />
                                                        <Route exact path="/account/register/yourdetails" component={AccountYourDetails} />
                                                        <Route exact path="/account/payment" component={AccountPayment} />
                                                        <Route exact path="/account/my-info" component={AccountMyInfo} />
                                                        <Route exact path="/account/addresses" component={AccountAddresses} />
                                                        <Route exact path="/account/my-wishlists" component={AccountMyWishList} />
                                                        <Route exact path="/account/order-history" component={AccountOrder} />
                                                    </DefaultPageWrapper>
                                                </>
                                            )
                                    }

                                })()}
                                <div className="ps-container">

                                    {/* {this.state.pageid === '' ? ( */}
                                    {/* // ) : ( */}
                                    {/* //    // <><Pages params={{ pageid: this.state.pageid }} key={this.state.key} /></> */}
                                    {/* // ) */}
                                    {/* } */}
                                    <Route exact path="/articles/:id" component={Articles} />
                                    {/* <Route exact path="/group/:id/:name" component={ProductsByID} /> */}
                                    {/* <Route exact path="/search/:id" component={searchItems} /> */}
                                    <Route exact path="/brand/:id/:name" component={ProductsByBrandID} />
                                    {/* <Route exact path="/product/:id/:id" component={ProductsDetailsByID} /> */}
                                    {/* <Route exact path="/cart" component={MyCart} />
                                        <Route exact path="/login" component={Login} />
                                        <Route exact path="/payment" component={Payment} />
                                        <Route exact path="/account/login" component={AccountLogin} />
                                        <Route exact path="/account/forgot" component={AccountForgot} />
                                        <Route exact path="/account/" component={AccountMember} />
                                        <Route exact path="/account/member" component={AccountMember} />
                                        <Route exact path="/account/register" component={AccountRegister} />
                                        <Route exact path="/account/register/verify/:id" component={AccountVerify} />
                                        <Route exact path="/account/register/yourdetails" component={AccountYourDetails} />
                                        <Route exact path="/account/payment" component={AccountPayment} />
                                        <Route exact path="/account/my-info" component={AccountMyInfo} />
                                        <Route exact path="/account/addresses" component={AccountAddresses} />
                                        <Route exact path="/account/my-wishlists" component={AccountMyWishList} />
                                        <Route exact path="/account/order-history" component={AccountOrder} /> */}
                                </div>
                                {(() => {

                                    switch (config.get('Theme')) {
                                        case 'Cloths':
                                            return (
                                                <>
                                                    <ClothingFooter />
                                                </>
                                            )
                                        case 'Martfury':
                                            return (
                                                <>
                                                    <MartfuryFooter />
                                                </>
                                            )
                                        case 'Apparel':
                                            return (
                                                <>
                                                    <ApparelFooter />
                                                </>
                                            )
                                        case 'Apparel-casi':
                                            return (
                                                <>
                                                    <ApparelcasiFooter />
                                                </>
                                            )
                                        case 'ComingSoon':
                                            return (
                                                <>

                                                </>
                                            )
                                        case 'Witmeg':
                                            return (
                                                <>
                                                    <WitmegFooter />
                                                </>
                                            )
                                        case 'Spiceway':
                                            return (
                                                <>
                                                    <SpicewayFooter />
                                                </>
                                            )
                                        case 'Stationary':
                                            return (
                                                <>
                                                    <StationaryFooter />
                                                </>
                                            )
                                        case 'MeatShop':
                                            return (
                                                <>
                                                    <MeatShopFooter />
                                                </>
                                            )
                                        default:
                                            return (<>
                                                <Footer />
                                            </>
                                            )
                                    }

                                })()}
                                <div>
                                    {/* <MessengerCustomerChat
                                            pageId={config.get('FBpageid')}
                                            appId={config.get('FBappid')}
                                        /> */}
                                </div>
                                {/* </div> */}



                            </Router>
                        )
                        : <SiteUnderMaintenance />
                }
            </React.Fragment>

        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    syncRestaurantMenuCartStateData: () => dispatch(syncRestaurantMenuCartDetails()),
    initializeCompanyData: (data) => dispatch(setCompanyData(data)),
    initializeSettingsData: (data) => dispatch(setSettingsData(data)),

});

export default connect(null, mapDispatchToProps)(Application);