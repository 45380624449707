import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { useParams, useHistory } from 'react-router';
import { useSelector, useDispatch } from "react-redux";
import { getCurrencyFormat, getLocalStorageData, LOCAL_STORAGE_ITEMS_NAMES } from "../../utilities/helper/helper";
import { Button, Col, Icon, Row, TextArea, Typography } from "witmeg-ui-system";
import "./SingleItemPages.scss";
import arrow from "../../../../../images/down-arrow.svg";
import { fetchAllRestaurantMenuData } from "../../../../../../../redux/resturantWidgetSlice";
import { Radio, Checkbox, } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { addToCart } from "../../utilities/api/restaurantsService";
import spiceImage from "../../assets/images/spice-level.svg"

const IMAGE_PLACEHOLDER = "https://st2.depositphotos.com/3904951/8925/v/450/depositphotos_89250312-stock-illustration-photo-picture-web-icon-in.jpg";

const SingleItemPage = () => {

    const [item, setItem] = useState(localStorage.getItem("ITEM") ? JSON.parse(localStorage.getItem("ITEM")) : null);
    const showContent = (itemValue) => {
        let list = ["Ingredients", "Nutritional", "Instructions", "Allergies"];
        if (itemValue) {
            if (!itemValue?.DishInfo?.Classifications?.Ingredients || itemValue?.DishInfo?.Classifications?.Ingredients?.length === 0) {
                list = list.filter(content => content !== "Ingredients");
            }
            if (!itemValue?.Allergens || itemValue?.Allergens?.length === 0) {
                list = list.filter(content => content !== "Allergies");
            }
            if (itemValue?.DishInfo?.Classifications?.InstructionsForUse || itemValue?.DishInfo?.Classifications?.InstructionsForUse === "") {
                list = list.filter(content => content !== "Instructions");
            }
        }

        return list;
    };
    const [selectedDetails, setSelectedDetails] = useState(showContent(item)[0]);
    const [comments, setComments] = useState("");
    const [isOpen, setIsOpen] = useState([]);
    const [quantities, setQuantities] = useState(localStorage.getItem("QUANTITY") ? JSON.parse(localStorage.getItem("QUANTITY")) : []);
    const [cartDetails, setCartDetails] = useState({});
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory()
    const {
        menuData: {
            storage: { data }
        },
        currency,
    } = useSelector((state) => state.resturantData);
    const { companyDetails } = useSelector((state) => state.companyData);

    const selectedOrderType = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) && JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)).defaultOrderType
    const currencyFormat = getCurrencyFormat(currency);
    //getting order type for cart
    const getOrderType = () => {
        let type;
        switch (selectedOrderType) {
            case "delivery":
                type = "DELIVERY_BY_REWARDCONSUMER";
                break;
            case "table":
                type = "DINE_IN";
                break;
            case "takeaway":
                type = "TAKE_AWAY";
                break;
            default:
                type = "DELIVERY_BY_REWARDCONSUMER";
                break;
        }
        return type
    }
    useEffect(() => {
        const fetchData = async () => {
            const val = await getLocalStorageData(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CART_DATA);
            const userDetails = localStorage.getItem('fullUserDetails')
            let parsedUserDetails = JSON.parse(localStorage.getItem('fullUserDetails'))
            let customer = {
                ID: localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_USER_ID),
                Name: {
                    DisplayName: userDetails ? parsedUserDetails.UserName : "",
                    FirstName: userDetails ? parsedUserDetails.FirstName : "",
                    LastName: userDetails ? parsedUserDetails.LastName : "",
                },
                Email: userDetails ? parsedUserDetails.Email : "",
                IsPrimaryCustomer: true

            }
            let cart = {
                "OrderID": localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) ? localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_CART_ORDER_ID) : '',
                "StoreID": val.cloudLocationID,
                "ConsumerID": localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_USER_ID),
                "OrderingPlatformType": "RETAILPACX",
                "CountryISOCode": "GB",
                "OrganizationID": val.organizationID,
                "CloudLocationID": val.cloudLocationID,
                "Name": companyDetails.shopName,
                "OrderFullFilmentType": getOrderType(),
                "Customers": [customer]
            }
            setCartDetails(cart)
            dispatch(fetchAllRestaurantMenuData({
                CloudLocationID: val.cloudLocationID,
                OrganizationID: val.organizationID,
            }));
        };
        fetchData();
    }, [dispatch]);
    useEffect(() => {
        if (data && data.Items) {
            const selectedItem = _.find(data.Items, { MenuItemID: params.id });
            if (selectedItem) {
                let newQuantityValue = localStorage.getItem("QUANTITY") ? JSON.parse(localStorage.getItem("QUANTITY"))[0].Quantity.Amount : 1
                let cartObject = {
                    "CartItemID": selectedItem.MenuItemID,
                    "Title": selectedItem.Title?.en,
                    "CustomerID": localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_USER_ID),
                    "Quantity": {
                        "Unit": "pcs",
                        "Amount": newQuantityValue
                    },
                    "DefaultQuantity": {
                        "Amount": 1,
                        "Unit": "pcs"
                    },
                    "CustomerRequests": {
                        "Allergy": {
                            "Allergens": [],
                            "Instructions": ""
                        },
                        "SpecialInstructions": comments
                    },
                    SelectedModifierGroups: [],
                    Id: uuidv4(),
                    ID: params.itemId !== "new" ? params.itemId : "",
                    ModifierGroupRules: selectedItem.ModifierGroupRules,
                    Image: selectedItem.ImageURL,
                    PriceInfo: getItemPriceForCart(selectedItem.PriceInfo, newQuantityValue)

                }

                let newItem = { ...selectedItem, Quantity: newQuantityValue, SelectedModifierGroups: [], Id: uuidv4() }
                let newQuantity = localStorage.getItem("QUANTITY") ? JSON.parse(localStorage.getItem("QUANTITY")) : [cartObject]
                setItem(newItem);
                setSelectedDetails(showContent(newItem)[0])

                localStorage.setItem("ITEM", JSON.stringify(newItem))
                if (JSON.parse(localStorage.getItem("QUANTITY"))) {
                    let quantityStorage = JSON.parse(localStorage.getItem("QUANTITY"))
                    let value = [{ ...quantityStorage[0], ModifierGroupRules: selectedItem.ModifierGroupRules, }]
                    setQuantities(value)
                    localStorage.setItem("QUANTITY", JSON.stringify(value))

                } else {
                    setQuantities(newQuantity)
                    localStorage.setItem("QUANTITY", JSON.stringify(newQuantity))
                }
            }
        }
    }, [data, params.id]);
    const getThePrice = (price, qty) => {
        let priceValue;
        switch (selectedOrderType) {
            case "delivery":
                priceValue = price.DeliveryPrice;
                break;
            case "table":
                priceValue = price.TablePrice;
                break;
            case "takeaway":
                priceValue = price.PickupPrice;
                break;
            default:
                priceValue = price.DeliveryPrice;
                break;
        }

        return currencyFormat((priceValue * qty) / 100);
    };
    const getThePriceItem = (overrides, Id, quantity) => {
        let price = getOptionPriceForCart(overrides, Id, quantity).BaseTotalPrice
        let priceValue;
        switch (selectedOrderType) {
            case "delivery":
                priceValue = price.Delivery.FormattedText;
                break;
            case "table":
                priceValue = price.DineIn.FormattedText;
                break;
            case "takeaway":
                priceValue = price.TakeAway.FormattedText;
                break;
            default:
                priceValue = price.Delivery.FormattedText;
                break;
        }

        return priceValue;
    };
    const getPriceSelectedOption = (selectedItem) => {
        let price = selectedItem.PriceInfo.BaseTotalPrice
        let priceValue;
        switch (selectedOrderType) {
            case "delivery":
                priceValue = price.Delivery.FormattedText;
                break;
            case "table":
                priceValue = price.DineIn.FormattedText;
                break;
            case "takeaway":
                priceValue = price.TakeAway.FormattedText;
                break;
            default:
                priceValue = price.Delivery.FormattedText;
                break;
        }
        return priceValue;
    }
    const getOptionPrice = (overrides, Id, quantity) => {
        let option = _.find(overrides, { ContextValue: Id })
        if (option) {
            return getThePriceItem(overrides, Id, quantity)
        } else {
            return "£0.00"
        }

    }
    const getOptionPriceForCart = (overrides, Id, quantity) => {
        const option = _.find(overrides, { ContextValue: Id });
        const createPriceObj = (price, quantity) => ({
            Amount: price * quantity,
            FormattedText: currencyFormat((price * quantity) / 100),
            FormattedAmount: Number(((price * quantity) / 100).toFixed(2))
        });

        const getPriceData = (price, quantity) => ({
            Delivery: createPriceObj(price?.DeliveryPrice || 0, quantity),
            TakeAway: createPriceObj(price?.PickupPrice || 0, quantity),
            DineIn: createPriceObj(price?.TablePrice || 0, quantity),
            CurrencyCode: cartDetails.currency
        });

        const basePrice = getPriceData(option?.Price || {}, 1);
        const totalPrice = getPriceData(option?.Price || {}, quantity);

        return {
            BaseUnitPrice: basePrice,
            BaseTotalPrice: quantity > 1 ? totalPrice : basePrice
        };
    };
    const getItemPriceForCart = (option, quantity) => {
        const createPriceObj = (price, quantity) => ({
            Amount: price * quantity,
            FormattedText: currencyFormat((price * quantity) / 100),
            FormattedAmount: Number(((price * quantity) / 100).toFixed(2))
        });

        const getPriceData = (price, quantity) => ({
            Delivery: createPriceObj(price?.DeliveryPrice || 0, quantity),
            TakeAway: createPriceObj(price?.PickupPrice || 0, quantity),
            DineIn: createPriceObj(price?.TablePrice || 0, quantity),
            CurrencyCode: cartDetails.currency
        });

        const basePrice = getPriceData(option?.Price || {}, 1);
        const totalPrice = getPriceData(option?.Price || {}, quantity);
        return {
            BaseUnitPrice: basePrice,
            BaseTotalPrice: quantity > 1 ? totalPrice : basePrice
        };
    };
    const updatePrice = (priceInfo, qty) => {
        let totalsVal = {
            "Delivery": {
                "Amount": priceInfo.BaseUnitPrice.Delivery.Amount * qty,
                "FormattedText": currencyFormat((priceInfo.BaseUnitPrice.Delivery.Amount * qty) / 100),
                "FormattedAmount": Number(((priceInfo.BaseUnitPrice.Delivery.Amount * qty) / 100).toFixed(2))
            },
            "TakeAway": {
                "Amount": priceInfo.BaseUnitPrice.TakeAway.Amount * qty,
                "FormattedText": currencyFormat((priceInfo.BaseUnitPrice.TakeAway.Amount * qty) / 100),
                "FormattedAmount": Number(((priceInfo.BaseUnitPrice.TakeAway.Amount * qty) / 100).toFixed(2))
            },
            "DineIn": {
                "Amount": priceInfo.BaseUnitPrice.DineIn.Amount * qty,
                "FormattedText": currencyFormat((priceInfo.BaseUnitPrice.DineIn.Amount * qty) / 100),
                "FormattedAmount": Number(((priceInfo.BaseUnitPrice.DineIn.Amount * qty) / 100).toFixed(2))

            },
            "CurrencyCode": "GBP"
        }
        let price = { ...priceInfo, BaseTotalPrice: totalsVal }
        return price
    }

    //increase Item quantity
    const increaseQty = () => {
        let newItem = { ...item, Quantity: item.Quantity ? item.Quantity + 1 : 1 }
        setItem(newItem)
        localStorage.setItem("ITEM", JSON.stringify(newItem))
        let update = {
            ...quantities[0], Quantity: {
                ...quantities[0].Quantity, Amount: newItem.Quantity,

            }, PriceInfo: getItemPriceForCart(newItem.PriceInfo, newItem.Quantity)
        }
        setQuantities([update])
        localStorage.setItem("QUANTITY", JSON.stringify([update]))

    };

    //decrease Item quantity
    const decreaseQty = () => {
        if (item.Quantity > 1) {
            let newItem = { ...item, Quantity: item.Quantity ? item.Quantity - 1 : 0 }
            setItem(newItem)
            localStorage.setItem("ITEM", JSON.stringify(newItem))
            let update = {
                ...quantities[0], Quantity: {
                    ...quantities[0].Quantity, Amount: newItem.Quantity,
                },
                PriceInfo: getItemPriceForCart(newItem.PriceInfo, newItem.Quantity)
            }
            setQuantities([update])
            localStorage.setItem("QUANTITY", JSON.stringify([update]))
            if (newItem.Quantity === 0) {
                setQuantities([])
                localStorage.removeItem("QUANTITY")
            }
        }
    };

    const toggleModifierGroup = (id) => {
        setIsOpen((prevIsOpen) => {
            if (prevIsOpen.includes(id)) {
                return prevIsOpen.filter((groupId) => groupId !== id);
            } else {
                return [...prevIsOpen, id];
            }
        });
    };

    const flattenArray = (arr) => {
        let result = [];
        arr.forEach(item => {
            result.push(item);
            if (item.SelectedModifierGroups && item.SelectedModifierGroups.length > 0) {
                result = result.concat(flattenArray(item.SelectedModifierGroups));
            }
        });
        return result;
    };

    //update childre modifiers qunatities
    const updateChildQuantities = (child, multiplier, isIncreasing = true) => {
        if (child.SelectedModifierGroups.length > 0) {
            child.SelectedModifierGroups.forEach(selectedMod => {
                selectedMod.SelectedItems.forEach(selected => {
                    if (isIncreasing) {
                        selected.Quantity.Amount = multiplier * selected.Quantity.Amount;
                        selected.PriceInfo = updatePrice(selected.PriceInfo, selected.Quantity.Amount)

                    } else {
                        if (selected.Quantity.Amount !== 1) {
                            selected.Quantity.Amount = Math.round(selected.Quantity.Amount / multiplier);
                            selected.PriceInfo = updatePrice(selected.PriceInfo, selected.Quantity.Amount)
                        }
                    }
                    updateChildQuantities(selected, child.Quantity.Amount, isIncreasing);
                });
            });
        }
    }

    //update modifier qunatities (increase)
    const updateParentQuantities = (prev = [], optionValue, modifierVal, parent) => {
        return new Promise((resolve, reject) => {
            const updatedQuantities = JSON.parse(JSON.stringify(prev || []))
            let option = {
                CartItemID: optionValue.MenuItemID ? optionValue.MenuItemID : optionValue.CartItemID,
                Title: optionValue?.Title?.en ? optionValue?.Title.en : optionValue?.Title,
                Id: optionValue.Id,
                ParentID: optionValue.ParentID,
                Quantity:
                    { Unit: "pcs", Amount: optionValue.Quantity ? optionValue.Quantity.Amount : 1 },
                SelectedModifierGroups: [],
                ModifierGroupRules: optionValue.ModifierGroupRules,
                PriceInfo: getOptionPriceForCart(optionValue?.PriceInfo?.Overrides, modifierVal.ModifierGroupID, optionValue.Quantity ? optionValue.Quantity.Amount : 1)
            }

            if (!option) {
                return resolve(updatedQuantities);
            }
            const updateRecursive = (item, modifier, parentValue) => {
                return new Promise((resolveRecursive, rejectRecursive) => {
                    const sameParent = item.ModifierGroupRules.IDs.findIndex(id => id === modifier.ModifierGroupID)
                    if (sameParent !== -1) {
                        if (item.SelectedModifierGroups.length > 0) {
                            const parentIndex = item.SelectedModifierGroups.findIndex(it => it.ID === modifier.ModifierGroupID);
                            if (parentIndex !== -1) {
                                const itemValue = item.SelectedModifierGroups[parentIndex];
                                const optionIndex = itemValue.SelectedItems.findIndex(o => o.CartItemID === option.CartItemID)
                                if (item.Id === option.ParentID) {
                                    if (optionIndex !== -1) {
                                        itemValue.SelectedItems[optionIndex].Quantity.Amount += 1;
                                        itemValue.SelectedItems[optionIndex].PriceInfo = updatePrice(itemValue.SelectedItems[optionIndex].PriceInfo, itemValue.SelectedItems[optionIndex].Quantity.Amount)
                                        if (itemValue.SelectedItems.length > 0) {
                                            itemValue.SelectedItems.forEach(child => {
                                                if (option.Id === child.Id) {
                                                    updateChildQuantities(child, itemValue.SelectedItems[optionIndex]?.Quantity.Amount, true);
                                                }
                                            });
                                        }
                                        resolveRecursive();
                                    } else {
                                        itemValue.SelectedItems.push({
                                            ...option,
                                            Quantity: { Unit: "pcs", Amount: 1 },
                                        })
                                        resolveRecursive();
                                    }
                                }
                            } else {
                                item.SelectedModifierGroups.push({
                                    SelectedItems: [{
                                        ...option,
                                        Quantity: { Unit: "pcs", Amount: 1 },
                                    }],
                                    ID: modifier.ModifierGroupID,
                                    Title: modifier.Title.en,
                                    ModifierOptions: modifier.ModifierOptions,
                                    QuantityConstraintsRules: modifier.QuantityConstraintsRules,
                                    ParentID: modifier.ParentID
                                });
                                resolveRecursive();
                            }

                        } else {
                            if (item.Id === option.ParentID) {
                                item.SelectedModifierGroups.push({

                                    SelectedItems: [{
                                        ...option,
                                        Quantity: { Unit: "pcs", Amount: 1 },
                                    }],
                                    ID: modifier.ModifierGroupID,
                                    Title: modifier.Title.en,
                                    ModifierOptions: modifier.ModifierOptions,
                                    QuantityConstraintsRules: modifier.QuantityConstraintsRules,
                                    ParentID: modifier.ParentID
                                });
                                resolveRecursive();
                            }
                        }
                    } else {
                        for (let mod of item.SelectedModifierGroups) {
                            for (let childMod of mod.SelectedItems) {
                                updateRecursive(childMod, modifier, parentValue).then(() => resolve(updatedQuantities))
                                    .catch(reject);

                            }
                        }

                    }
                });
            };

            updateRecursive(updatedQuantities[0], modifierVal, parent)
                .then(() => resolve(updatedQuantities))
                .catch(reject);
        });
    };

    //wrapper function to add modifier qunatity
    const addQtyOfModifiers = async (option, modifier, parent) => {
        try {
            const updated = await updateParentQuantities(quantities, option, modifier, parent);
            setQuantities(updated);
            localStorage.setItem("QUANTITY", JSON.stringify(updated))
        } catch (error) {
            console.error('Error updating quantities:', error);
        }
    };

    //update modifier qunatities (decrease)
    const reduceParentQuantities = (prev = [], optionValue, modifierVal, parent) => {
        return new Promise((resolve, reject) => {
            const updatedQuantities = JSON.parse(JSON.stringify(prev || []));
            let option = {
                CartItemID: optionValue.MenuItemID ? optionValue.MenuItemID : optionValue.CartItemID,
                Title: optionValue?.Title?.en ? optionValue?.Title.en : optionValue?.Title,
                Id: optionValue.Id,
                ParentID: optionValue.ParentID,
                Quantity:
                    { Unit: "pcs", Amount: optionValue.Quantity ? optionValue.Quantity.Amount : 1 },
                SelectedModifierGroups: [],
                ModifierGroupRules: optionValue.ModifierGroupRules,
                PriceInfo: getOptionPriceForCart(optionValue?.PriceInfo?.Overrides, modifierVal.ModifierGroupID, optionValue.Quantity ? optionValue.Quantity.Amount : 1)
            };

            if (!option) {
                return resolve(updatedQuantities);
            }

            const reduceRecursive = (item, modifier, parentValue) => {
                return new Promise((resolveRecursive, rejectRecursive) => {
                    const sameParent = item.ModifierGroupRules.IDs.findIndex(id => id === modifier.ModifierGroupID);
                    if (sameParent !== -1) {
                        if (item.SelectedModifierGroups.length > 0) {
                            const parentIndex = item.SelectedModifierGroups.findIndex(it => it.ID === modifier.ModifierGroupID);
                            if (parentIndex !== -1) {
                                const itemValue = item.SelectedModifierGroups[parentIndex];
                                const optionIndex = itemValue.SelectedItems.findIndex(o => o.CartItemID === option.CartItemID);

                                if (item.Id === option.ParentID) {
                                    if (optionIndex !== -1) {
                                        if (itemValue.SelectedItems[optionIndex].Quantity.Amount > 1) {
                                            itemValue.SelectedItems[optionIndex].Quantity.Amount -= 1;
                                            itemValue.SelectedItems[optionIndex].PriceInfo = updatePrice(itemValue.SelectedItems[optionIndex].PriceInfo, itemValue.SelectedItems[optionIndex].Quantity.Amount)
                                            if (itemValue.SelectedItems.length > 0) {
                                                itemValue.SelectedItems.forEach(child => {
                                                    if (option.Id === child.Id) {
                                                        updateChildQuantities(child, itemValue.SelectedItems[optionIndex]?.Quantity.Amount, false);
                                                    }
                                                });
                                            }

                                            resolveRecursive();
                                        } else {
                                            itemValue.SelectedItems.splice(optionIndex, 1);
                                            resolveRecursive();
                                        }
                                    } else {
                                        resolveRecursive();
                                    }
                                } else {
                                    resolveRecursive();
                                }
                            } else {
                                resolveRecursive();
                            }
                        } else {
                            resolveRecursive();
                        }
                    } else {
                        for (let mod of item.SelectedModifierGroups) {
                            for (let childMod of mod.SelectedItems) {
                                reduceRecursive(childMod, modifier, parentValue)
                                    .then(() => resolve(updatedQuantities))
                                    .catch(reject);
                            }
                        }
                    }
                });
            };

            reduceRecursive(updatedQuantities[0], modifierVal, parent)
                .then(() => resolve(updatedQuantities))
                .catch(reject);
        });
    };

    //wrapper function to remove modifier qunatity
    const reduceQtyOfModifiers = (option, modifier, parent) => {
        return new Promise(async (resolve) => {
            const updated = await reduceParentQuantities(quantities, option, modifier, parent);
            setQuantities(updated);
            localStorage.setItem("QUANTITY", JSON.stringify(updated))
            resolve(true)
        })
    };

    //modiefier rule handler
    const getRule = (rules) => {
        if (rules?.MinPermitted === 1 && rules?.MaxPermitted === 1) {
            return "RULE_1"
        }
        if (rules?.MaxPermitted === 1) {
            return "RULE_2"
        }
        if (!rules?.IsMinPermittedOptional) {
            return "RULE_4"
        }
        if (rules?.MaxPermitted > 1) {
            return "RULE_3"
        }
    }

    // handle check box quantities
    const handleCheckbox = (option, parent, modVal, modifier) => {
        if (!option) return "";
        let ID = option.MenuItemID ? option.MenuItemID : option.CartItemID
        let obj = _.find(modVal?.SelectedItems, { CartItemID: ID });
        if (obj && obj.Quantity.Amount > 0) {
            reduceQtyOfModifiers(option, modifier, parent)
        } else {
            addQtyOfModifiers(option, modifier, parent)
        }
    }

    //handle radio quantity
    const getSelectedRadio = (modifier, modVal) => {
        return modifier.ModifierOptions.map((options) => {
            const option = _.find(data.Items, { MenuItemID: options.Id });
            if (!option) return "";
            let ID = option.MenuItemID ? option.MenuItemID : option.CartItemID

            let obj = _.find(modVal?.SelectedItems, { CartItemID: ID });

            if (obj && obj.Quantity.Amount > 0) {
                return obj
            } else {
                return ""
            }
        }).filter(val => val !== "")[0]

    }

    //render modifiers
    const renderModifiers = (modifier, parent, modVal) => {
        if (!modifier) return null;
        let rule = getRule(modifier?.QuantityConstraintsRules?.Quantity)
        let radio = getSelectedRadio(modifier, modVal)
        return (
            <div key={modifier.ModifierGroupID} className="modifier">
                <div className="single-item" onClick={() => toggleModifierGroup(modifier.ModifierGroupID)}>
                    {modifier.Title.en.toCapitalFirst()}
                    <div className="left">{rule === "RULE_1" ? <div className="req">Required</div> : ""}
                        <img alt="arrow" src={arrow} className={`arrow-icon ${isOpen.includes(modifier.ModifierGroupID) ? "open" : ""}`} />
                    </div>

                </div>

                <Radio.Group
                    style={{ width: "100%" }}
                    onChange={async (e) => {
                        const selectedOption = e.target.value;
                        console.log(selectedOption, "selectedOption")
                        if (!selectedOption) return;

                        const selectedItem = _.find(data.Items, { MenuItemID: selectedOption.Id });
                        if (modVal) {
                            let selectedModifierObj = _.find(modVal.SelectedItems, { CartItemID: selectedItem?.MenuItemID });
                            if (!selectedModifierObj || selectedModifierObj.Quantity.Amount === 0) {
                                addQtyOfModifiers(selectedOption, modifier, parent);
                            }

                            const optionIndex = modVal.SelectedItems.findIndex(o => o.CartItemID === selectedItem?.MenuItemID);
                            if (optionIndex === -1) {
                                modVal.SelectedItems.map(async (modifiers) => {
                                    const object = modifier.ModifierOptions.find(options =>
                                        options.Id === modifiers.CartItemID
                                    );
                                    const modIndex = modVal.SelectedItems.findIndex(o => o.CartItemID === modifiers.MenuItemID);
                                    if (object) {
                                        modVal.SelectedItems.splice(modIndex, 1);
                                        return await reduceQtyOfModifiers(object, modifier, parent);
                                    }
                                });
                                if (modVal.SelectedItems.length === 0) {
                                    addQtyOfModifiers(selectedOption, modifier, parent);
                                }
                            } else {
                                await reduceQtyOfModifiers(selectedOption, modifier, parent);
                            }
                        } else {
                            addQtyOfModifiers(selectedOption, modifier, parent);

                        }


                    }}
                    value={radio}

                >


                    {isOpen.includes(modifier.ModifierGroupID) &&
                        <>
                            {rule === "RULE_4" && <div className="res">*atleast you have to choose {modifier?.QuantityConstraintsRules?.Quantity?.MinPermitted} items</div>}
                            {rule === "RULE_3" && <div className="res">*you can only choose up to {modifier?.QuantityConstraintsRules?.Quantity?.MaxPermitted} items</div>}
                            {rule === "RULE_3" && modVal?.
                                SelectedItems.length > 0 && modVal?.
                                    SelectedItems.reduce((sum, item) => sum + item.Quantity.Amount, 0) > modifier?.QuantityConstraintsRules?.Quantity?.MaxPermitted && <div className="res" style={{ color: "red" }}>*you have chosen {modVal?.
                                        SelectedItems.reduce((sum, item) => sum + item.Quantity.Amount, 0)} items, please choose {modifier?.QuantityConstraintsRules?.Quantity?.MaxPermitted} items</div>}
                            {modifier.ModifierOptions.map((options) => {
                                const optionValue = _.find(data.Items, { MenuItemID: options.Id });
                                const newOption = { ...optionValue, ParentID: parent.Id, Id: uuidv4(), SelectedModifierGroups: [] }
                                let obj = _.find(modVal?.SelectedItems, { CartItemID: optionValue.MenuItemID });
                                const selectedItem = _.find(data.Items, { MenuItemID: optionValue.MenuItemID });
                                let newObj = { ...obj, ModifierGroupRules: selectedItem.ModifierGroupRules }
                                let option = obj ? newObj : newOption
                                let radioOption = obj ? obj : newOption

                                if (!option) return null;
                                return (
                                    <div className="modifier-option-container" key={options.Id}>
                                        <div className="modifier-details">
                                            {rule === "RULE_1" ? (
                                                <Radio style={{ marginTop: "-6px" }} value={radioOption} />
                                            ) : (rule === "RULE_2" || rule === "RULE_4") ? (
                                                <Checkbox style={{ marginTop: "-6px", marginRight: "10px" }}
                                                    onChange={() => { handleCheckbox(option, parent, modVal, modifier) }}
                                                    checked={obj?.Quantity.Amount && obj?.Quantity.Amount > 0 ? true : false} />
                                            ) : null}

                                            <div className="option-name">
                                                <div className="item-name"> {option.Title.en ? option.Title.en.toCapitalFirst() : option.Title.toCapitalFirst()}</div>
                                                <div className="price-val">{obj ? getPriceSelectedOption(obj) : getOptionPrice(option.PriceInfo.Overrides, modifier.ModifierGroupID, obj?.Quantity.Amount || 1)}</div>
                                            </div>

                                            {rule === "RULE_3" && (
                                                <div className="qlt-option">
                                                    <div className="icon-cont" onClick={() => reduceQtyOfModifiers(option, modifier, parent)}>
                                                        <Icon name="minus" className="icon" />
                                                    </div>
                                                    <div className="qty-cont">
                                                        {obj?.Quantity.Amount || 0}
                                                    </div>
                                                    <div className="icon-cont" onClick={() => {
                                                        if (modifier.QuantityConstraintsRules.Quantity.MaxPermittedUnique > 0 && (obj?.Quantity.Amount || 0) < modifier.QuantityConstraintsRules.Quantity.MaxPermittedUnique) {
                                                            addQtyOfModifiers(option, modifier, parent)
                                                        }
                                                    }}>
                                                        <Icon name="plus" className="icon" />
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="option-modifier">
                                            {quantities?.length > 0 && option?.ModifierGroupRules?.IDs?.map((mod) => {
                                                const newMod = obj && obj?.SelectedModifierGroups ? _.find(obj.SelectedModifierGroups, { ID: mod }) : null;
                                                const nestedModifier = _.find(data.ModifierGroups, { ModifierGroupID: mod });
                                                const modifierWithID = { ...nestedModifier, ParentID: option.Id }
                                                if (obj && obj.Quantity.Amount > 0) {
                                                    return renderModifiers(modifierWithID, option, newMod);
                                                }
                                                return null;
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </>

                    }
                </Radio.Group>

            </div >
        );
    };
    const getNutritionFacts = () => {
        const value = item?.NutrientData;

        const nutrients = [
            { label: 'Kcal', value: value?.Calories?.EnergyInterval?.Upper ?? 'N/A' },
            { label: 'Proteins', value: `${value?.Protein?.Amount?.Interval?.Upper ?? 'N/A'} ${value?.Protein?.Amount?.Weight?.UnitType ?? ''}` },
            { label: 'Fats', value: `${value?.Fat?.Amount?.Interval?.Upper ?? 'N/A'} ${value?.Fat?.Amount?.Weight?.UnitType ?? ''}` },
            { label: 'Carbo H', value: `${value?.Carbohydrates?.Amount?.Interval?.Upper ?? 'N/A'} ${value?.Carbohydrates?.Amount?.Weight?.UnitType ?? ''}` },
        ];

        return (
            <div className="nutri">
                <Typography bold={true} className="nutri-title">Nutritional value per 100g</Typography>
                <div className="servings-container">
                    {nutrients.map((nutrient, index) => (
                        <div className="serving-text-cont" key={index}>
                            <Typography bold={true} className="serving-text-head">{nutrient.value}</Typography>
                            <Typography className="serving-text">{nutrient.label}</Typography>
                        </div>
                    ))}
                </div>
            </div>
        );
    };


    const getDetailsData = () => {
        if (selectedDetails === "Ingredients") {
            return <>
                <div className="eco-mb-24">
                    This product contains ingredients that may trigger allergies. Please review the ingredient list for details.

                </div>
                <div className="det">
                    {(item?.DishInfo?.Classifications?.Ingredients?.map((ingredient) => (<div className="item">{ingredient}</div>)))}
                </div>
            </>
        }
        else if (selectedDetails === "Nutritional") {
            return getNutritionFacts()
        }
        else if (selectedDetails === "Allergies") {
            return (
                <div className="det">
                    {(item?.Allergens?.map((allergen) => (<div className="item">{allergen}</div>)))}
                </div>
            )
        }
        else {
            return (item?.DishInfo?.Classifications?.InstructionsForUse)
        }
    }
    const renderSpiceImages = (level) => {
        const fullImages = Math.floor(level / 2);
        const hasHalfImage = (level % 2) !== 0;
        const images = Array.from({ length: fullImages }, (_, index) => (
            <img key={index} src={spiceImage} alt="spice-level" style={{ width: '20px' }} />
        ));
        if (hasHalfImage) {
            images.push(
                <span key="half" style={{ display: 'flex', width: '12px', overflow: 'hidden' }}>
                    <img src={spiceImage} alt="half-spice-level" style={{ width: '20px' }} />
                </span>
            );
        }

        return images;
    };
    return (
        item && (
            <div className="SingleItemPage">
                {/* <Spin tip="Loading"
                    className="eco-mt-44 spinner" size="large"> </Spin> */}
                <Row>
                    <Col>
                        <img
                            alt={item?.Title?.en ?? "No Image Available"}
                            src={item?.ImageURL || IMAGE_PLACEHOLDER}
                            className="item-image"
                        />
                    </Col>
                    <Col className="item-details">

                        <Typography className="title">
                            {item?.Title?.en.toCapitalFirst()}
                        </Typography>
                        {/* <div className="review">
                            <img alt="star" src={star} className="star" />
                            {item.TotalReviews}
                        </div> */}
                        <Typography className="item-price">
                            {getThePrice(item?.PriceInfo?.Price, item?.Quantity)}
                        </Typography>
                        <Typography className="details">
                            {item?.Description?.en.toCapitalFirst()}
                        </Typography>
                        <div className="item-details-data eco-mt-24">
                            {item?.DishInfo?.Classifications?.SpiceLevel > 0 && <Row><Col className="spice-cont">
                                <Typography className="spice-level">Spice Level</Typography>
                                <div className="spice-leve-img">{renderSpiceImages(item.DishInfo.Classifications.SpiceLevel)}</div>
                            </Col></Row>}
                            {item?.DishInfo?.Classifications?.IsVegetarian && <div className="vegan">Vegan</div>}

                        </div>
                    </Col>
                </Row>
                <div className="description">
                    <div className="item-details-tab">
                        {showContent(item).map((detail) => {
                            return (
                                <div
                                    key={detail}
                                    className={`det-itm ${selectedDetails === detail ? "selected" : ""}`}
                                    onClick={() => setSelectedDetails(detail)}
                                >
                                    {detail}
                                </div>
                            )
                        }
                        )}
                    </div>
                    <div className="item-tab-desc">
                        {getDetailsData()}
                    </div>
                </div>

                <div className="modifiers">
                    {item?.ModifierGroupRules?.IDs.map((idVal) => {
                        const modifier = _.find(data?.ModifierGroups, { ModifierGroupID: idVal });
                        const newMod = quantities[0]?.SelectedModifierGroups && quantities[0]?.SelectedModifierGroups?.length > 0 ? _.find(quantities[0]?.SelectedModifierGroups, { ID: modifier?.ModifierGroupID }) : null
                        const modifierWithID = { ...modifier, ParentID: quantities[0].Id }
                        if (modifier) {
                            return renderModifiers(modifierWithID, quantities[0], newMod);
                        }
                    })}
                </div>

                <div className="comments-sec">
                    <Typography className="comments-title" bold={true}>
                        Add Comments (Optional)
                    </Typography>
                    <TextArea
                        placeholder="Write here"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                        className="text-area"
                    />
                </div>
                <div className="bottom-container">
                    <div className="quantity">
                        <div className="icon-cont" onClick={decreaseQty}>
                            <Icon name="minus" className="icon" />
                        </div>
                        <div className="qty-cont">
                            {item?.Quantity || 0}
                        </div>
                        <div className="icon-cont" onClick={increaseQty}>
                            <Icon name="plus" className="icon" />
                        </div>
                    </div>
                    <Button className="sec-btn" onClick={async () => {
                        let data = { ...cartDetails, Cart: quantities[0] }
                        await addToCart(data).then((response) => {
                            if (response) {
                                history.push('/')
                            }
                        })
                    }}>
                        {params.itemId !== "new" ? "Update Cart" :
                            " Add to Cart"}
                    </Button>
                </div>
            </div>
        )
    );
};


export default SingleItemPage;
