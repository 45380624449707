import React, { useState } from 'react';
import { Stack, Typography, Icon, Space, Button } from 'witmeg-ui-system';
import "../styles/styles.scss"
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import "@fontsource/inter";
import "@fontsource/plus-jakarta-sans";
import RetailCartSummary from '../../../../common/RetailCartSummary';
import { getCurrencyFormat, isECommerceProcessPath, LOCAL_STORAGE_ITEMS_NAMES } from '../../utilities/helper/helper';
import { deleteCartItem, updateCartItemQuantity } from '../../utilities/api/restaurantsService';
import { setOrderCartItems, setOrderData, setOrderEmpty } from '../../../../../../../redux/resturantWidgetSlice';
import DeletePopup from './DeletePopup';

const CartPopup = ({ toggleVisibility, updateCartItemCallback, websiteType }) => {
    const [isDeleteItem, setIsDeleteItem] = useState("")
    const history = useHistory();
    const dispatch = useDispatch()
    const IMAGE_PLACEHOLDER = "https://st2.depositphotos.com/3904951/8925/v/450/depositphotos_89250312-stock-illustration-photo-picture-web-icon-in.jpg";
    const restaurantDataState = useSelector((state) => state.resturantData);
    const retailDataState = useSelector((state) => state.retailData);
    const selectedOrderTypeOriginal = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) && JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)).defaultOrderType
    const selectedOrderType = websiteType === "Restaurant" ? selectedOrderTypeOriginal : "retail"
    const isCartDataAvailable = restaurantDataState.orderCartData && restaurantDataState.orderCartData.Items.length ? true : false;

    const isRetailCartAvailable = retailDataState.cart && retailDataState.cart.length > 0 ? true : false

    const isButtonsDisabled = !isCartDataAvailable || isECommerceProcessPath();

    const isOrderSuccessPage = window.location.pathname.split('/')[1] === 'order-success' ? true : false;
    const currencyFormat = getCurrencyFormat(restaurantDataState.currency);
    const onCheckoutClick = () => {
        history.push('/checkout');
        toggleVisibility();
    };
    const onCheckoutClickRestaurant = () => {
        history.push('/order/checkout');
        toggleVisibility();
    };
    const onContinueShoppingClick = () => toggleVisibility();
    const showCartDetails = () => {
        toggleVisibility();
        history.push('/cart')
    }
    const updateTotalPrice = (price, quantity) => {
        const createPriceObj = (priceValue, quantity) => ({
            Amount: priceValue * quantity,
            FormattedText: currencyFormat((priceValue * quantity) / 100),
            FormattedAmount: Number(((priceValue * quantity) / 100).toFixed(2))
        });

        const getPriceData = (price, quantity) => ({
            Delivery: createPriceObj(price?.Delivery.Amount || 0, quantity),
            TakeAway: createPriceObj(price?.TakeAway.Amount || 0, quantity),
            DineIn: createPriceObj(price?.DineIn.Amount || 0, quantity),
            CurrencyCode: "GBP"
        });
        const totalPrice = getPriceData(price, quantity);
        return totalPrice
    }
    const increaseQty = (item) => {
        let data = {
            "CartItemID": item.ID,
            "NewQuantity": item.Quantity.Amount + 1,
            "CountryISOCode": "GB"
        }
        const updatedArray = restaurantDataState.orderCartData.Items.map((itemValue) =>
            itemValue.ID === item.ID
                ? {
                    ...itemValue, Quantity: { ...itemValue.Quantity, Amount: item.Quantity.Amount + 1 }, PriceInfo: {
                        ...itemValue.PriceInfo,
                        BaseTotalPrice: updateTotalPrice(item.PriceInfo.BaseUnitPrice, item.Quantity.Amount + 1),
                        TotalPrice: updateTotalPrice(item.PriceInfo.UnitPrice, item.Quantity.Amount + 1)

                    }
                }
                : itemValue
        );
        dispatch(setOrderCartItems(updatedArray))
        updateCartItemQuantity(data).then((response) => {
            dispatch(setOrderData(response))
        })
    }
    const decreaseQty = (item) => {
        let data = {
            "CartItemID": item.ID,
            "NewQuantity": item.Quantity.Amount - 1,
            "CountryISOCode": "GB"
        }
        if (data.NewQuantity > 0) {
            const updatedArray = restaurantDataState.orderCartData.Items.map((itemValue) =>
                itemValue.ID === item.ID
                    ? {
                        ...itemValue, Quantity: { ...itemValue.Quantity, Amount: item.Quantity.Amount - 1 }, PriceInfo: {
                            ...itemValue.PriceInfo,
                            BaseTotalPrice: updateTotalPrice(item.PriceInfo.BaseUnitPrice, item.Quantity.Amount - 1),
                            TotalPrice: updateTotalPrice(item.PriceInfo.UnitPrice, item.Quantity.Amount - 1),
                        }
                    }
                    : itemValue
            );
            dispatch(setOrderCartItems(updatedArray))
            updateCartItemQuantity(data).then((response) => {
                dispatch(setOrderData(response))
            })

        } else {
            let cartArray = restaurantDataState.orderCartData.Items.filter((cartItem) => cartItem.ID !== item.ID)
            dispatch(setOrderCartItems(cartArray))
            deleteCartItem(item.ID).then((response) => {
                if (response) {
                    if (restaurantDataState.orderCartData.Items.length > 1) {
                        let cartArray = restaurantDataState.orderCartData.Items.filter((cartItem) => cartItem.ID !== isDeleteItem)
                        dispatch(setOrderCartItems(cartArray))
                        setIsDeleteItem("")
                    }
                    else {
                        dispatch(setOrderEmpty())
                        setIsDeleteItem("")
                    }
                }
            })
        }
    }
    const deleteItem = () => {
        if (isDeleteItem !== "") {
            deleteCartItem(isDeleteItem).then((response) => {
                if (response) {
                    if (restaurantDataState.orderCartData.Items.length > 1) {
                        let cartArray = restaurantDataState.orderCartData.Items.filter((cartItem) => cartItem.ID !== isDeleteItem)
                        dispatch(setOrderCartItems(cartArray))
                        setIsDeleteItem("")
                    } else {
                        dispatch(setOrderEmpty())
                        setIsDeleteItem("")
                    }
                }
            })
        }
    }

    const getPrice = (
        baseTotalPrice
    ) => {
        let priceValue;
        switch (selectedOrderType) {
            case "delivery":
                priceValue = baseTotalPrice.Delivery.FormattedText;
                break;
            case "table":
                priceValue = baseTotalPrice.DineIn.FormattedText;
                break;
            case "takeaway":
                priceValue = baseTotalPrice.TakeAway.FormattedText;
                break;
            default:
                priceValue = baseTotalPrice.Delivery.FormattedText;
                break;
        }
        return priceValue
    }
    const getTotalPrice = (
        baseTotalPrice
    ) => {
        let priceValue;
        switch (selectedOrderType) {
            case "delivery":
                priceValue = baseTotalPrice.Delivery.Amount;
                break;
            case "table":
                priceValue = baseTotalPrice.DineIn.Amount;
                break;
            case "takeaway":
                priceValue = baseTotalPrice.TakeAway.Amount;
                break;
            default:
                priceValue = baseTotalPrice.Delivery.Amount;
                break;
        }
        return priceValue
    }
    const getCartTotal = () => {
        let total = currencyFormat(restaurantDataState.orderCartData.Items.reduce((accumulator, currentItem) => {
            return accumulator + getTotalPrice(currentItem.PriceInfo.BaseTotalPrice)
        }, 0) / 100)
        return total

    }
    const getRestaurantComponent = () => {
        return (
            <>
                <Stack
                    horizontalAlign="space_between" className="eco-order-summary-row-rest">
                    <Typography type="text" className="eco-order-summary-label">VAT</Typography>
                    <Typography type="text" pattern="bold" className="eco-order-summary-total">
                        £ 0.00
                    </Typography>
                </Stack>
                <div className="eco-mt-36">
                    <Stack horizontalAlign="space_between" className="eco-order-summary-total-row-rest">
                        <Typography type="text" pattern="bold" className="eco-order-summary-total-label">Order total:</Typography>
                        <Typography type="text" pattern="bold" className="eco-order-summary-total-bg-rest">
                            {getCartTotal()}
                        </Typography>
                    </Stack>
                    <div className='rest-btn-conatiner eco-mt-12'>
                        <Button fullWidth variant="primary" className="eco-mb-12 eco-checkout-button" onClick={onCheckoutClickRestaurant} disabled={isButtonsDisabled}>
                            Check Out
                        </Button>
                        <Button fullWidth variant="outline" className="eco-mb-12 eco-continue-shopping-button" onClick={onContinueShoppingClick} disabled={isOrderSuccessPage}>
                            Continue Shopping
                        </Button>
                    </div>
                </div>

            </>
        )
    }
    const getOtherComponent = () => {
        return (
            <div className="eco-mt-24">
                <Stack
                    horizontalAlign="space_between" className="eco-order-summary-row">
                    <Typography type="text" className="eco-order-summary-label">VAT</Typography>
                    <Typography type="text" pattern="bold" className="eco-order-summary-total">
                        {/* {currencyFormat(isCartDataAvailable ? restaurantDataState.cartDetails.total[selectedOrderType]?.vatAmount : 0)} */}
                    </Typography>
                </Stack>
                <Stack horizontalAlign="space_between" className="eco-order-summary-total-row">
                    <Typography type="text" pattern="bold" className="eco-order-summary-total-label">Total:</Typography>
                    <Typography type="text" pattern="bold" className="eco-order-summary-total-bg">
                        {retailDataState.cart.reduce((accumulator, currentItem) => {
                            return accumulator + currentItem.productDetails.SellingPricesArray.SellingPrice * currentItem.quantity;
                        }, 0)}
                    </Typography>
                </Stack>
                <>
                    <Button fullWidth variant="primary" className="eco-mb-12 eco-checkout-button" onClick={onCheckoutClick} disabled={!isRetailCartAvailable}>
                        Check Out
                    </Button>
                    <Button fullWidth variant="outline" className="eco-mb-12 eco-continue-shopping-button" onClick={showCartDetails} disabled={isOrderSuccessPage}>
                        View Cart
                    </Button>
                </>

            </div>)
    }

    return (
        <div className='cart-popup'>
            <div className="eco-product-list">
                {websiteType === "Restaurant" ?
                    <>
                        {restaurantDataState.orderCartData.Items.length > 0 ? restaurantDataState.orderCartData.Items.map((item, index) => (
                            <Stack className="item eco-mb-20 " verticalAlign="v_start">
                                <Typography
                                    style={{ backgroundImage: `url(${item.PictureURL !== "" ? item.PictureURL : IMAGE_PLACEHOLDER})` }}
                                    className="cart-summary-image">
                                </Typography>

                                <Stack direction="column" verticalAlign="v_start" className="eco-product-addon-row">
                                    <Stack horizontalAlign="space_between" className="eco-product-addon-content">
                                        <div>
                                            <Typography type="text" className="eco-mb-12 truncate-text">
                                                {item.Title}
                                            </Typography>
                                            <Space className="eco-product-addon">
                                                <div className="eco-product-addon-mins"
                                                    onClick={() => {
                                                        decreaseQty(item)
                                                    }}
                                                >
                                                    <Icon name="minus" size={16} />
                                                </div>
                                                <div className="eco-product-addon-count">
                                                    <input className="input-order-amount" value={item.Quantity.Amount} readOnly />
                                                </div>
                                                <div className="eco-product-addon-plus"
                                                    onClick={() => {
                                                        increaseQty(item)
                                                    }}
                                                >
                                                    <Icon name="plus" size={16} />
                                                </div>
                                            </Space >
                                        </div>
                                        <div>
                                            <Typography type="text" pattern="bold" className="eco-mb-12 eco-accordion-addon-price">
                                                {getPrice(item.PriceInfo.TotalPrice)}
                                            </Typography>
                                            <Space className="eco-product-add" >
                                                <div className='eco-product-add-ctl'
                                                    onClick={() => {
                                                        setIsDeleteItem(item.ID)
                                                    }}
                                                >
                                                    <Icon name="delete" size={16} />
                                                </div>
                                                <div className='eco-product-add-ctl'
                                                    onClick={() => {
                                                        localStorage.setItem("QUANTITY", JSON.stringify([item]))
                                                        toggleVisibility();
                                                    }}
                                                >
                                                    <a href={'/item/' + item.CartItemID + "/" + item.ID}>

                                                        <Icon name="pencil" size={16} />

                                                    </a>
                                                </div>
                                            </Space>
                                        </div>

                                    </Stack>
                                </Stack>
                            </Stack>
                        )) :
                            <Stack className="item eco-mb-16 eco-mt-16" style={{ display: "flex", justifyContent: "center" }}>
                                <div>No cart items</div>
                            </Stack>

                        }
                    </>
                    : <div>
                        <RetailCartSummary />
                    </div>
                }
            </div>
            {websiteType === "Restaurant" ? getRestaurantComponent() : getOtherComponent()}
            <DeletePopup
                visibility={isDeleteItem !== ""}
                onPopupClose={() => {
                    setIsDeleteItem("")
                }}
                onDeleteConfirm={deleteItem} />
        </div>
    );

}
export default CartPopup