import React, { useState, useEffect } from "react";
import { Button, Col, Row, Typography, Icon, Stack, Space } from 'witmeg-ui-system'
import { useHistory } from 'react-router'
import "./OrderPage.scss"
import { useSelector, useDispatch } from 'react-redux';
import { getCurrencyFormat, LOCAL_STORAGE_ITEMS_NAMES } from "../../utilities/helper/helper";
import { deleteCartItem, getFloorDetails, placeOrder, updateCartItemQuantity } from "../../utilities/api/restaurantsService";
import { setOrderCartItems, setOrderData, setOrderEmpty } from "../../../../../../../redux/resturantWidgetSlice";
import DeletePopup from "../../components/Cart/DeletePopup";
import { Select } from "antd";

const OrderSummaryPage = () => {
    const restaurantDataState = useSelector((state) => state.resturantData);
    const [orderType, setOrderType] = useState(restaurantDataState.orderDetails.FulfillmentType)
    const [floorData, setFloorData] = useState([])
    const [tables, setTables] = useState([])
    const [selectedFloor, setSelectedFloor] = useState(null)
    const [selectedTable, setSelectedTable] = useState(null)
    const [selectedURLFloor, setSelectedURLFloor] = useState(null)
    const [selectedURLTable, setSelectedURLTable] = useState(null)
    const { Option } = Select;
    const [isDeleteItem, setIsDeleteItem] = useState("")
    const customerDetails = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CUSTOMER_DETAILS) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_CUSTOMER_DETAILS)) : {}
    const defaultDetails = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) && JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA))
    let history = useHistory();
    const [dineInError, setDineInError] = useState(false)

    const dispatch = useDispatch()

    const onBackToMenu = () => {
        history.push("/order/checkout")
    }
    const orderTypes = localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA) && JSON.parse(localStorage.getItem(LOCAL_STORAGE_ITEMS_NAMES.ECOM_RESTAURANT_DATA)).orderTypes

    const isCartDataAvailable = restaurantDataState.orderCartData && restaurantDataState.orderCartData.Items.length ? true : false;
    const IMAGE_PLACEHOLDER = "https://st2.depositphotos.com/3904951/8925/v/450/depositphotos_89250312-stock-illustration-photo-picture-web-icon-in.jpg";
    const currencyFormat = getCurrencyFormat(restaurantDataState.currency);
    useEffect(() => {
        if (defaultDetails && defaultDetails.defaultOrderType === "table" && orderType === "DINE_IN") {
            getFloorDetails().then((response) => {
                if (response) {
                    setFloorData(response)
                    let value = response.map((floor) =>
                        floor.Tables.map((table) => {
                            if (table.LocalID === defaultDetails.tableLocalId) {
                                let floorDataValue = {
                                    Id: floor.ID,
                                    Name: floor.ItemName.Translations.en,
                                    LocalReference: { Id: floor.LocalID }
                                }
                                let data = {
                                    TableID: table.ID,
                                    TableName: table.TableName,
                                    LocalReference: { Id: table.LocalID },
                                    Floor: floorDataValue
                                }
                                setSelectedFloor(floorDataValue)
                                setSelectedTable(data)
                                setSelectedURLFloor(floorDataValue)
                                setSelectedURLTable(data)
                                return data;
                            }
                            return null;
                        })
                    )
                        .flat()
                        .filter(Boolean)[0];
                    let updatedData = { ...restaurantDataState.orderDetails, FulfillmentType: orderType, DineIn: value }
                    dispatch(setOrderData(updatedData))
                }
            })
        }
    }, [])
    useEffect(() => {
        let updatedData = { ...restaurantDataState.orderDetails, FulfillmentType: orderType }
        if (orderType === "DINE_IN") {
            getFloorDetails().then((response) => {
                if (response) {
                    setFloorData(response)
                }
            })

        } else {
            updatedData = { ...updatedData, DineIn: {} }
        }
        dispatch(setOrderData(updatedData))

    }, [orderType])
    useEffect(() => {
        if (selectedFloor) {
            setSelectedURLFloor(null)
            setSelectedURLTable(null)
            setSelectedTable(null)
            setTables([])
            setTimeout(() => {
                let Tables = floorData.find((floor) => floor.ID === selectedFloor.Id).Tables
                let selected = Tables[0]
                let tableValue = {
                    TableID: selected.ID,
                    TableName: selected.TableName,
                    LocalReference: { Id: selected.LocalID },
                    Floor: selectedFloor
                }
                setSelectedTable(tableValue)
                setTables(Tables)
            }, 0);
        }
    }, [selectedFloor])
    const updateTotalPrice = (price, quantity) => {
        const createPriceObj = (priceValue, quantity) => ({
            Amount: priceValue * quantity,
            FormattedText: currencyFormat((priceValue * quantity) / 100),
            FormattedAmount: Number(((priceValue * quantity) / 100).toFixed(2))
        });

        const getPriceData = (price, quantity) => ({
            Delivery: createPriceObj(price?.Delivery.Amount || 0, quantity),
            TakeAway: createPriceObj(price?.TakeAway.Amount || 0, quantity),
            DineIn: createPriceObj(price?.DineIn.Amount || 0, quantity),
            CurrencyCode: "GBP"
        });
        const totalPrice = getPriceData(price, quantity);
        return totalPrice
    }
    const increaseQty = (item) => {
        let data = {
            "CartItemID": item.ID,
            "NewQuantity": item.Quantity.Amount + 1,
            "CountryISOCode": "GB"
        }
        const updatedArray = restaurantDataState.orderCartData.Items.map((itemValue) =>
            itemValue.ID === item.ID
                ? {
                    ...itemValue, Quantity: { ...itemValue.Quantity, Amount: item.Quantity.Amount + 1 },
                    PriceInfo: {
                        ...itemValue.PriceInfo,
                        BaseTotalPrice: updateTotalPrice(item.PriceInfo.BaseUnitPrice, item.Quantity.Amount + 1),
                        TotalPrice: updateTotalPrice(item.PriceInfo.UnitPrice, item.Quantity.Amount + 1)
                    }
                }
                : itemValue
        );
        dispatch(setOrderCartItems(updatedArray))
        updateCartItemQuantity(data).then((response) => {
            dispatch(setOrderData(response))
        })
    }
    const decreaseQty = (item) => {
        let data = {
            "CartItemID": item.ID,
            "NewQuantity": item.Quantity.Amount - 1,
            "CountryISOCode": "GB"
        }
        if (data.NewQuantity > 0) {
            const updatedArray = restaurantDataState.orderCartData.Items.map((itemValue) =>
                itemValue.ID === item.ID
                    ? {
                        ...itemValue, Quantity: { ...itemValue.Quantity, Amount: item.Quantity.Amount - 1 },
                        PriceInfo: {
                            ...itemValue.PriceInfo,
                            BaseTotalPrice: updateTotalPrice(item.PriceInfo.BaseUnitPrice, item.Quantity.Amount - 1),
                            TotalPrice: updateTotalPrice(item.PriceInfo.UnitPrice, item.Quantity.Amount - 1),

                        }
                    }
                    : itemValue
            );
            dispatch(setOrderCartItems(updatedArray))
            updateCartItemQuantity(data).then((response) => {
                dispatch(setOrderData(response))
            })

        } else {
            let cartArray = restaurantDataState.orderCartData.Items.filter((cartItem) => cartItem.ID !== item.ID)
            dispatch(setOrderCartItems(cartArray))
            deleteCartItem(item.ID).then((response) => {
                if (response) {
                    if (restaurantDataState.orderCartData.Items.length > 1) {
                        let cartArray = restaurantDataState.orderCartData.Items.filter((cartItem) => cartItem.ID !== isDeleteItem)
                        dispatch(setOrderCartItems(cartArray))
                        setIsDeleteItem("")
                    } else {
                        dispatch(setOrderEmpty())
                        setIsDeleteItem("")
                        history.push("/")
                    }
                }
            })
        }
    }
    const deleteItem = () => {
        if (isDeleteItem !== "") {
            deleteCartItem(isDeleteItem).then((response) => {
                if (response) {
                    if (restaurantDataState.orderCartData.Items.length > 1) {
                        let cartArray = restaurantDataState.orderCartData.Items.filter((cartItem) => cartItem.ID !== isDeleteItem)
                        dispatch(setOrderCartItems(cartArray))
                        setIsDeleteItem("")
                    } else {
                        dispatch(setOrderEmpty())
                        setIsDeleteItem("")
                        history.push("/")
                    }
                }
            })
        }
    }


    const getPrice = (
        basePrice
    ) => {
        let priceValue;
        switch (orderType) {
            case "DELIVERY_BY_REWARDCONSUMER":
                priceValue = basePrice.Delivery.FormattedText;
                break;
            case "DINE_IN":
                priceValue = basePrice.DineIn.FormattedText;
                break;
            case "TAKE_AWAY":
                priceValue = basePrice.TakeAway.FormattedText;
                break;
            default:
                priceValue = basePrice.Delivery.FormattedText;
                break;
        }
        return priceValue
    }
    const getTotalPrice = (
        baseTotalPrice
    ) => {
        let priceValue;
        switch (orderType) {
            case "DELIVERY_BY_REWARDCONSUMER":
                priceValue = baseTotalPrice.Delivery.Amount;
                break;
            case "DINE_IN":
                priceValue = baseTotalPrice.DineIn.Amount;
                break;
            case "TAKE_AWAY":
                priceValue = baseTotalPrice.TakeAway.Amount;
                break;
            default:
                priceValue = baseTotalPrice.Delivery.Amount;
                break;
        }
        return priceValue
    }
    const getCartTotal = () => {
        let total = currencyFormat(restaurantDataState.orderCartData.Items.reduce((accumulator, currentItem) => {
            return accumulator + getTotalPrice(currentItem.PriceInfo.BaseTotalPrice)
        }, 0) / 100)
        return total

    }
    const renderModifierGroups = (selectedGroup) => {
        return (
            <div>
                <span>{selectedGroup.Title} ({selectedGroup.SelectedItems.length})</span>
                {selectedGroup.SelectedItems.map((selectedItem, index) => (
                    <span key={index}>
                        <span className="addon-title">
                            {selectedItem.Title} ({getPrice(selectedItem.PriceInfo.TotalPrice)})
                        </span>
                        {selectedItem.SelectedModifierGroups && selectedItem.SelectedModifierGroups.length > 0 && (
                            <span>
                                {selectedItem.SelectedModifierGroups.map((nestedGroup, nestedIndex) => (
                                    <span key={nestedIndex}> {renderModifierGroups(nestedGroup)}</span>
                                ))}
                            </span>
                        )}
                        {index < selectedGroup.SelectedItems.length - 1 && <span>, </span>}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <div className='OrderPage order-confirmation-page'>
            <Button className="eco-order-confirm-back-but eco-mb-24" category="icon-text" icon="chevron-left" text={"Back"} iconSize={16} variant="subtle" onClick={onBackToMenu} />
            <div className="Order-confirmation">
                <Typography type="title" level={1} className="eco-mb-32 eco-order-confirm-title" color="gray_darkest">Order Summary</Typography>
                <div className="eco-mb-16">
                    <div className="order-form eco-mb-36">
                        <div className="form-header">Delivery Detail</div>
                        <div className="order-form-container">
                            <Row className="eco-mb-24" gutter={[16, 16]}>
                                <Col lg={12} sm={24} xs={24}>
                                    <Row className="eco-mb-24">
                                        <Col lg={5} className="details-label" >Customer Name :</Col> <Col className="details-content">{customerDetails?.Name?.FirstName} {customerDetails?.Name?.LastName}</Col> </Row>
                                    <Row>
                                        <Col lg={5} className="details-label">Customer Address :</Col>
                                        <Col className="details-content">
                                            {customerDetails?.BillingAddress?.AddressLine1} {customerDetails?.BillingAddress?.StateProvinceRegion}, {customerDetails?.BillingAddress?.Country} {customerDetails?.BillingAddress?.PostZipCode} </Col></Row>

                                </Col>
                                <Col lg={12} sm={24} xs={24}>
                                    <Row><Col lg={5} className="details-label">Delivery Address :</Col> <Col className="details-content">{customerDetails?.DeliveryAddress?.AddressLine1} {customerDetails?.DeliveryAddress?.StateProvinceRegion}, {customerDetails?.DeliveryAddress?.Country} {customerDetails?.DeliveryAddress?.PostZipCode}
                                        <div className='eco-product-add-ctl'
                                        >
                                            <a href={'/order/checkout'}>
                                                <Icon name="pencil" size={16} />

                                            </a>
                                        </div>
                                    </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="order-form">
                        <div className="form-header">
                            <Row gutter={[16, 16]}>
                                <Col lg={12} sm={24} xs={24}>Order Summary</Col>
                                <Col lg={12} sm={24} xs={24} className="toggle-container">
                                    <div className="toggle-tab">
                                        {orderTypes && orderTypes.includes("delivery") && <div className={`toggle-item ${orderType === "DELIVERY_BY_REWARDCONSUMER" ? 'selected' : ""}`}
                                            onClick={() => {
                                                setOrderType("DELIVERY_BY_REWARDCONSUMER")
                                            }}
                                        >Delivery</div>}
                                        {orderTypes && orderTypes.includes("takeaway") && <div className={`toggle-item ${orderType === "TAKE_AWAY" ? 'selected' : ""}`}
                                            onClick={() => {
                                                setOrderType("TAKE_AWAY")
                                            }}
                                        >Collection</div>}
                                        {orderTypes && orderTypes.includes("table") && <div className={`toggle-item ${orderType === "DINE_IN" ? 'selected' : ""}`}
                                            onClick={() => {
                                                setOrderType("DINE_IN")
                                            }}
                                        >Dine In</div>}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="order-form-container">
                            {orderType === "DINE_IN" && <div className="floor-dropdown">
                                <Row gutter={[16, 16]}>
                                    <Col lg={6} className="floor-drop-container">
                                        <div className="label">Floor</div>
                                        <Select className="select-floor" onChange={(id) => {
                                            let selected = floorData.find((floor) => floor.ID === id)
                                            let floorValue = {
                                                Id: selected.ID,
                                                Name: selected.ItemName.Translations.en,
                                                LocalReference: { Id: selected.LocalID }
                                            }
                                            setSelectedFloor(floorValue)
                                            setDineInError(false)
                                        }}
                                            value={selectedURLFloor ? selectedURLFloor.Name : selectedFloor ? selectedFloor.Name : ""}
                                        >
                                            {floorData && floorData?.map((floor, key) => {
                                                return (
                                                    <Option value={floor.ID} key={key}>
                                                        {floor.ItemName.Translations.en}
                                                    </Option>
                                                )
                                            }
                                            )}
                                        </Select>
                                    </Col>
                                    <Col lg={6} className="floor-drop-container">
                                        {tables.length > 0 && <>
                                            <div className="label">Table</div>
                                            <Select className="select-floor"
                                                onChange={(id) => {
                                                    let selected = tables.find((table) => table.ID === id)
                                                    let tableValue = {
                                                        TableID: selected.ID,
                                                        TableName: selected.TableName,
                                                        LocalReference: { Id: selected.LocalID },
                                                        Floor: selectedFloor
                                                    }
                                                    setSelectedTable(tableValue)
                                                    setDineInError(false)

                                                }}
                                                value={selectedURLTable ? selectedURLTable.TableName : selectedTable ? selectedTable.TableName : ""}
                                            >
                                                <Option value=''>
                                                </Option>
                                                {tables && tables?.map((table, key) => {
                                                    return (
                                                        <Option value={table.ID} key={key}>
                                                            {table.TableName}
                                                        </Option>
                                                    )
                                                }
                                                )}
                                            </Select>
                                        </>
                                        }
                                    </Col>
                                </Row>
                            </div>}

                            <div className="eco-mb-16 desktop-view">
                                {isCartDataAvailable ?

                                    <table className="eco-order-summary-table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>PRODUCT</th>
                                                <th>PRICE</th>
                                                <th>QUANTITY</th>
                                                <th>TOTAL PRICE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isCartDataAvailable && restaurantDataState.orderCartData.Items.map((item, index) => {
                                                const _hasAddons = item.SelectedModifierGroups.length > 0;

                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <div onClick={() => {
                                                                setIsDeleteItem(item.ID)
                                                            }}>
                                                                <Icon name="cancel" type="filled" className="close-icon" />
                                                            </div>
                                                        </td>
                                                        <td valign={_hasAddons ? 'top' : 'center'}>
                                                            <Stack horizontalAlign="h_start" verticalAlign={_hasAddons ? 'v_start' : 'v_center'}>
                                                                <div
                                                                    style={{ backgroundImage: `url(${item.PictureURL !== "" ? item.PictureURL : IMAGE_PLACEHOLDER})` }}
                                                                    className="eco-order-summary-image" />
                                                                <Stack direction="column" verticalAlign="v_start" className="eco-ml-32">
                                                                    <Space size="middle" className={_hasAddons && "eco-mb-24"}>
                                                                        <Typography type="title" className="item-name">
                                                                            {item.Title}
                                                                        </Typography>
                                                                    </Space>
                                                                    {_hasAddons && item.SelectedModifierGroups.map((selectedGroup, index) => (
                                                                        <span key={index}> {renderModifierGroups(selectedGroup)} </span>
                                                                    ))}

                                                                </Stack>
                                                            </Stack>
                                                        </td>
                                                        <td valign={_hasAddons ? 'top' : 'center'}>
                                                            <Stack direction="column" verticalAlign="v_start">
                                                                <Typography type="title" level={5} pattern="bold" className={_hasAddons && "eco-mb-24"}>
                                                                    {getPrice(item.PriceInfo.UnitPrice
                                                                    )}
                                                                </Typography>

                                                            </Stack>
                                                        </td>
                                                        <td valign={_hasAddons ? 'top' : 'center'}>
                                                            <div style={{ border: '1px solid #EAE8ED', borderRadius: '6px', width: 'fit-content', display: 'flex', alignItems: 'center' }}>
                                                                <div style={{ padding: '5px', borderRight: '1px solid #EAE8ED', cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        decreaseQty(item)
                                                                    }}
                                                                >
                                                                    <Icon name="minus" size={16} />
                                                                </div>
                                                                <div style={{ borderRight: '1px solid #EAE8ED' }}>
                                                                    <input className='input-order-amount' value={item.Quantity.Amount} readOnly />
                                                                </div>
                                                                <div style={{ padding: '5px', cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        increaseQty(item)
                                                                    }}
                                                                >
                                                                    <Icon name="plus" size={16} readOnly />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td valign={_hasAddons ? 'top' : 'center'} style={{ textAlign: 'center' }}>
                                                            <Typography type="title" level={5} pattern="bold">{getPrice(item.PriceInfo.TotalPrice
                                                            )}</Typography>
                                                            <Space size="middle" className="eco-mt-24">
                                                                <div className='eco-product-add-ctl'
                                                                    onClick={() => {
                                                                        localStorage.setItem("QUANTITY", JSON.stringify([item]))
                                                                    }}
                                                                >
                                                                    <a href={'/item/' + item.CartItemID + "/" + item.ID}>

                                                                        <Icon name="pencil" size={16} />

                                                                    </a>
                                                                </div></Space>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            <tr >
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td colSpan={2} >
                                                    <Stack direction="column" verticalAlign="v_end" className="eco-mb-36">
                                                        <Stack horizontalAlign="space_between" className="eco-mb-36">
                                                            <Typography type="text">Sub total</Typography>
                                                            <Typography type="text" pattern="bold" style={{ marginRight: "10px" }}>
                                                                {restaurantDataState.orderDetails.Payment.
                                                                    PaymentDetail.OrderTotal.Net.Formatted}
                                                            </Typography>
                                                        </Stack>
                                                        {/* {
                                                            orderType === 'DELIVERY_BY_REWARDCONSUMER' && (
                                                                <Stack horizontalAlign="space_between" className="eco-mb-36">
                                                                    <Typography type="text">Delivery Charge:</Typography>
                                                                    <Typography type="text" pattern="bold" style={{ marginRight: "10px" }}>
                                                                        £ 0.00
                                                                    </Typography>
                                                                </Stack>
                                                            )
                                                        } */}
                                                        {restaurantDataState?.orderDetails?.Payment.
                                                            PaymentDetail?.Fee?.Details?.map((fee, key) => {
                                                                return (
                                                                    <Stack horizontalAlign="space_between" className="eco-mb-36" key={key}>
                                                                        <Typography type="text">{fee.Description}</Typography>
                                                                        <Typography type="text" style={{ marginRight: "10px" }}>
                                                                            {fee.Amount.DisplayAmount}
                                                                        </Typography>
                                                                    </Stack>
                                                                )
                                                            })}

                                                        <Stack horizontalAlign="space_between" className="eco-mb-36">
                                                            <Typography type="text" pattern="bold">Total:</Typography>
                                                            <Typography type="text" pattern="bold">
                                                                <span style={{ backgroundColor: '#F4F4F4', padding: '10px', borderRadius: '8px', fontSize: "20px" }}>
                                                                    {restaurantDataState.orderDetails.Payment.
                                                                        PaymentDetail.OrderTotal.Gross.Formatted}
                                                                </span>
                                                            </Typography>
                                                        </Stack>
                                                        <Button
                                                            category={"icon-text"}
                                                            text="Proceed to Payment"
                                                            variant="primary"
                                                            color="white"
                                                            onClick={() => {
                                                                let customerData = [{ ...restaurantDataState.orderDetails.Customers[0], ...customerDetails }]
                                                                let updatedData = { ...restaurantDataState.orderDetails, Customers: customerData, FulfillmentType: orderType, DineIn: selectedTable ? selectedTable : {} }
                                                                if (orderType === "DINE_IN" && Object.keys(updatedData.DineIn).length === 0) {
                                                                    setDineInError(true)
                                                                } else {
                                                                    setDineInError(false)
                                                                    dispatch(setOrderData(updatedData))
                                                                    history.push("/order/payment/selection")
                                                                }

                                                            }}
                                                            className="proceed-payment-button"
                                                        />
                                                        {dineInError && <Typography className="error-text">*Select a table and floor to continue</Typography>}

                                                    </Stack>

                                                </td>

                                            </tr>

                                        </tbody>
                                    </table> :
                                    <div className="empty-cart">
                                        <div className="empty">
                                            <div className="empty-text  eco-mb-24"> No cart items</div>
                                            <Button
                                                category={"icon-text"}
                                                text="Continue Shopping"
                                                variant="primary"
                                                color="white"
                                                onClick={() => {
                                                    history.push("/")
                                                }}
                                                className="proceed-payment-button"
                                            />
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <DeletePopup
                visibility={isDeleteItem !== ""}
                onPopupClose={() => {
                    setIsDeleteItem("")
                }}
                onDeleteConfirm={deleteItem} />
        </div >
    )

}
export default OrderSummaryPage