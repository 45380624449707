import React from "react";
import { useHistory } from 'react-router'
import { Button, Typography, Row, Col } from 'witmeg-ui-system'
import "./OrderPage.scss"
import loc from "../../assets/images/location.svg"
import res from "../../assets/images/ion_restaurant.svg"
import { useSelector } from "react-redux";


const IMAGE_PLACEHOLDER = "https://st2.depositphotos.com/3904951/8925/v/450/depositphotos_89250312-stock-illustration-photo-picture-web-icon-in.jpg";

const OrderStatusPage = () => {
    let history = useHistory();
    const { orderTrackingData } = useSelector((state) => state.resturantData);
    const onBackToMenu = () => {
        history.push("/order/track")
    }
    const steps = [{
        head: "Order Confirmation",
        sub: "Your order has confirmed"
    }, {
        head: "Order Preparing",
        sub: "Restaurant is preparing your food"
    }, {
        head: "Delivery in Progress",
        sub: "Your order will be delivered soon"
    }, {
        head: "Order Completed",
        sub: `Thank You for ordering at ${orderTrackingData.Store.Name}`
    }
    ]
    const getStepsByStatus = (status) => {
        switch (status) {
            case 'CREATED':
                return steps.slice(0, 1);
            case 'PREPARING':
                return steps.slice(0, 2);
            case 'SCHEDULED':
                return steps.slice(0, 3);
            case 'FINISHED':
                return steps;
            default:
                return steps;
        }
    }
    return (
        <div className='OrderPage order-confirmation-page'>
            <Button className="eco-order-confirm-back-but eco-mb-24" category="icon-text" icon="chevron-left" text={"Back"} iconSize={16} variant="subtle" onClick={onBackToMenu} />

            <div className="Order-confirmation">
                <Typography type="title" level={1} className="eco-mb-32 eco-order-confirm-title" color="gray_darkest">Track your order</Typography>
                <div className="eco-mb-16">
                    <div className="order-form">
                        <div className="order-track-container">
                            <Row className="eco-mb-36" gutter={[16, 16]}>
                                <Col lg={14} sm={24} xs={24} className="order-track-left-container">

                                    <div className="del-container"><div className="loc-container"><img alt="" src={loc} /></div>
                                        <div>
                                            <div>Delivery Details</div>
                                            <div className="address-track">                                            {orderTrackingData.Customers[0].BillingAddress?.AddressLine1} {orderTrackingData.Customers[0].BillingAddress?.StateProvinceRegion}, {orderTrackingData.Customers[0].BillingAddress?.Country} {orderTrackingData.Customers[0].BillingAddress?.PostZipCode}</div></div>
                                    </div>
                                    <div className="track-steps-container">
                                        {getStepsByStatus(orderTrackingData.State).map((step, index) => {
                                            return (
                                                <>
                                                    <div className="steps-container" key={index}><div className="loc-container">{index + 1}</div>
                                                        {index + 1 !== getStepsByStatus(orderTrackingData.State).length && <div className="left-border-step" />}
                                                        <div>
                                                            <div>{step.head}</div>
                                                            <div className="content-track">{step.sub}</div>
                                                        </div>
                                                    </div>

                                                    {index + 1 !== getStepsByStatus(orderTrackingData.State).length && <div className="border-track" />}
                                                </>
                                            )
                                        })}


                                    </div>
                                    {/* <div className="trck-btn-container">
                                        <Button className="sec-btn" variant="primary">View receipt
                                        </Button>
                                        <Button className="sec-btn" >Reorder
                                        </Button>
                                    </div> */}

                                </Col>
                                <Col lg={8} sm={24} xs={24} className="order-track-right-container">
                                    <div className="restaurant-details">
                                        <div className="restp-header"><div className="loc-container"><img alt="" src={res} /></div>{orderTrackingData.Store.Name}</div>
                                        <div className="rest-container">
                                            <div className="order-status-rest">
                                                <Typography className="status-text">{getStepsByStatus(orderTrackingData.State)[getStepsByStatus(orderTrackingData.State).length - 1].head}</Typography>
                                                {/* <div className="rate-btn">Rate Order</div> */}
                                            </div>
                                            <div className="order-items">
                                                {orderTrackingData.Carts.Items.map((item, key) => {
                                                    return (
                                                        <div className="order-item" key={key}>
                                                            <img alt="" src={IMAGE_PLACEHOLDER} className="item-image" />
                                                            <div className="order-item-details">
                                                                <Typography className="item-text">{item.Title}</Typography>
                                                                {/* <Typography className="item-sub">Choose Size: Large (£23.75)</Typography> */}
                                                                <Typography className="item-qty">Qty: {item.Quantity.Amount}</Typography>

                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                            <div className="total-value-text">
                                                <Typography className="total-value">Total</Typography>
                                                <Typography className="total-value-price">{orderTrackingData.Payment.PaymentDetail.OrderTotal.DisplayAmount}</Typography>

                                            </div>

                                        </div>
                                    </div></Col>

                            </Row>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}
export default OrderStatusPage